import { requestHeaders, requestBody, handleResponse } from "_helpers";
import { setUserGroupDeleteReq, setUserGroupAddReq, setUserGroupUpReq, getUserGroupReq, getGroupUsersReq } from "_actions";

export function getGroupUsers(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
            }),
        };
        return fetch(`/api/groups/${params.grpid}/users`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getGroupUsersReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getGroups(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
            }),
        };
        return fetch(`/api/groups`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getUserGroupReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function setUserGroupDelete(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const requestOptions = {
            method: "DELETE",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
            }),
        };
        return fetch(`/api/groups?grpid=${params.grpid}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setUserGroupDeleteReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function setUserGroupUp(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const body = params;
        const requestOptions = {
            method: "PUT",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
            body: requestBody(body),
        };
        return fetch(`/api/groups`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setUserGroupUpReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function setUserGroupAdd(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const body = params;
        const requestOptions = {
            method: "POST",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
            body: requestBody(body),
        };
        return fetch(`/api/groups`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setUserGroupAddReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}
