/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import windowSize from "react-window-size";
import Pagination from "react-js-pagination";
import { commonUtils } from "_helpers";
import { getGateways, deleteGateway } from "_services";
import { Gateway } from "./Gateway";
import Aux from "../hoc/_Aux";
import i18n from "../i18n";
class GatewayManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isGateway: false,
            searchStr: "",
            sort: "createdtime",
            activePage: 1,
            pageSize: 14,
            currentPage: 1,
            gateways: [],
        };
        this._handleGateway = this._handleGateway.bind(this);
        this._handleSaveClose = this._handleSaveClose.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._getGateways = this._getGateways.bind(this);
        this._deleteGateway = this._deleteGateway.bind(this);
    }
    _deleteGateway(e, gid) {
        this.props.deleteGateway({ gid: gid }, (error, result) => {
            if (result) {
                this._getGateways();
            }
        });
    }
    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
    }

    _handleSaveClose() {
        this._getGateways();
        this._handleGateway();
    }
    _handleGateway() {
        this.setState({
            ...this.state,
            isGateway: !this.state.isGateway,
        });
    }
    _handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber,
            currentPage: pageNumber,
        });
    }
    componentDidMount() {
        this._getGateways();
    }

    _getGateways() {
        this.props.getGateways({ searchStr: this.state.searchStr }, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    gateways: result,
                });
            }
        });
    }
    render() {
        const apipaginate = commonUtils.dataPaging(this.state.gateways, this.state.currentPage, this.state.pageSize);
        return (
            <Aux>
                <div className="main_content">
                    <div className="page_content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page_title_box">
                                        <h4>{i18n.t("menu_atoz_testing")}</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="form_box">
                                                        <div className="input-group " style={{ width: "300px" }}>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={i18n.t("label_atoznm")}
                                                                name="searchStr"
                                                                value={this.state.searchStr}
                                                                onChange={this._handleChange}
                                                            />
                                                            <div className="input-group-append">
                                                                <button className="btn btn-secondary btn_ico" type="button" onClick={this._getGateways}>
                                                                    <i className="bx bx-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="text-sm-right">
                                                        <button type="button" className="btn btn-success" onClick={this._handleGateway}>
                                                            <i className="bx bx-plus"></i> {i18n.t("label_btn_addatoz")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table table-centered table-nowrap">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th>{i18n.t("label_atozid")}</th>
                                                            <th>{i18n.t("label_atoznm")}</th>
                                                            <th>{i18n.t("label_devicestatus")}</th>
                                                            <th style={{ width: "170px" }}>{i18n.t("label_batterylevel")}</th>
                                                            <th>{i18n.t("label_created")}</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.gateways &&
                                                            apipaginate.map((data) => (
                                                                <tr className="col-12" key={data.gatewayid}>
                                                                    <td>{data.gatewayid}</td>
                                                                    <td>{data.gatewaynm}</td>
                                                                    <td>Active</td>
                                                                    <td>
                                                                        <div className="progress" style={{ height: "23px" }}>
                                                                            <div
                                                                                // className="progress-bar progress-c-theme4"
                                                                                className={
                                                                                    "progress-bar " +
                                                                                    (data.battery > 50 ? "progress-c-theme2" : "progress-c-theme4")
                                                                                }
                                                                                role="progressbar"
                                                                                style={{
                                                                                    width: data.battery + "%",
                                                                                    height: "23px",
                                                                                    fontWeight: "bold",
                                                                                    fontSize: "9pt",
                                                                                    textAlign: "left",
                                                                                }}
                                                                                aria-valuenow={data.battery}
                                                                                aria-valuemin="0"
                                                                                aria-valuemax="100"
                                                                            >
                                                                                &nbsp;&nbsp;{data.battery} %
                                                                            </div>
                                                                        </div>
                                                                        {/* {data.battery} */}
                                                                    </td>
                                                                    <td>{data.createdtime}</td>
                                                                    <td className="btn_td">
                                                                        <button
                                                                            type="button"
                                                                            title="Delete"
                                                                            className="ico_btn delete"
                                                                            onClick={(e) => this._deleteGateway(e, data.gid)}
                                                                        >
                                                                            <i className="bx bxs-trash"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                                {this.state.gateways.length > 0 && (
                                                    <div className="data_page">
                                                        <Pagination
                                                            activePage={this.state.activePage}
                                                            itemsCountPerPage={14}
                                                            totalItemsCount={this.state.gateways.length}
                                                            pageRangeDisplayed={10}
                                                            onChange={this._handlePageChange.bind(this)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.isGateway && <Gateway open={this.state.isGateway} onClose={this._handleGateway} onSave={this._handleSaveClose} />}
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            deleteGateway,
            getGateways,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(GatewayManagement);
export { connectedPage as GatewayManagement };
// export default connect(mapStateToProps, mapDispatchToProps)(windowSize(GatewayManagement));
