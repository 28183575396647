/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCommonCode, setCommonCode } from "../_services";
import { openAlertDialog } from "../_actions";
// import { Constants } from "../_constants/constants";
import i18n from "../i18n";
class FeverRangeInterest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            temperature: "",
        };

        this._handleChange = this._handleChange.bind(this);
        this._setInterestFever = this._setInterestFever.bind(this);
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            ...this.state,
            [name]: value,
        });
    }

    componentDidMount() {
        this._getInterestFever();
    }

    _setInterestFever() {
        const session = JSON.parse(localStorage.getItem("session"));
        this.props.setCommonCode({ tenantid: session.tenantid, codeid: "C0008", customid1: this.state.temperature }, (error, result) => {
            if (result) {
                this.props.dispatch(openAlertDialog(i18n.t("msg_alert_saved"), false, "check", false, null, null, null, null));
                //console.log("Resutl", result);
            }
        });
    }

    _getInterestFever() {
        this.props.getCommonCode({ codeid: "C0007" }, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    temperature: result[0].customid1,
                });
            }
        });
    }

    render() {
        return (
            <>
                <div className="card cont_h100">
                    <div className="card-body">
                        <h4 className="card-title">{i18n.t("label_alertlevel")}</h4>
                        <div className="title_btn">
                            <button type="button" className="btn btn-success" onClick={this._setInterestFever}>
                                <i className="bx bx-check-double"></i> {i18n.t("label_btn_save")}
                            </button>
                        </div>

                        <div className="form-group row" id="codesRow">
                            <label htmlFor="example-text-input" className="col-sm-2 col-form-label text-right">
                                {i18n.t("label_temperature")}
                            </label>
                            <div className="col-sm-5">
                                <input
                                    type="text"
                                    className="form-control with_text"
                                    placeholder="From"
                                    name="temperature"
                                    value={this.state.temperature}
                                    onChange={this._handleChange}
                                />
                                {/* <span className="txt">이상</span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setCommonCode,
            getCommonCode,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(FeverRangeInterest);
export { connectedPage as FeverRangeInterest };
