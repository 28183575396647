/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Pagination from "react-js-pagination";
import { commonUtils } from "_helpers";
import { getUsers, setUserDeviceConnect } from "_services";
import { User } from "./User";
import { DeviceConnect } from "./DeviceConnect";
import { UserDevice } from "./UserDevice";
import i18n from "../i18n";
import sortBy from "lodash/sortBy";
// import { openAlertDialog } from "../_actions";
// import { Constants } from "../_constants/constants";

import Aux from "../hoc/_Aux";

class UserManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isUser: false,
            isDeviceConnect: false,
            isDevices: false,
            active: "A",
            searchStr: "",
            sort: "createdtime",
            activePage: 1,
            pageSize: 20,
            currentPage: 1,
            users: [],
            sortby: "asc",
            selectedUserid: "",
        };
        this._handleUser = this._handleUser.bind(this);
        this._handleSaveClose = this._handleSaveClose.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._handleSelectChange = this._handleSelectChange.bind(this);
        this._getUsers = this._getUsers.bind(this);
        this._handleDeviceConnect = this._handleDeviceConnect.bind(this);
        this._handleDeviceDisConnect = this._handleDeviceDisConnect.bind(this);
        this._handleDeviceReload = this._handleDeviceReload.bind(this);
        this._setDeviceDisconnect = this._setDeviceDisconnect.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this._handleNumberSort = this._handleNumberSort.bind(this);
        this._handleUserDevice = this._handleUserDevice.bind(this);
        this._handleDevice = this._handleDevice.bind(this);
    }
    _handleUserDevice(userid, cnt) {
        if (cnt > 1) {
            this.setState({
                ...this.state,
                isDevices: !this.state.isDevices,
                selectedUserid: userid,
            });
        }
    }
    _handleDevice() {
        this.setState({
            ...this.state,
            isDevices: !this.state.isDevices,
            selectedUserid: "",
        });
    }

    _handleNumberSort(sort) {
        this.setState((prevState) => ({
            users:
                prevState.sortby === "asc"
                    ? sortBy(this.state.users, (obj) => parseInt(obj[sort] ? obj[sort] : 0, 10))
                    : sortBy(this.state.users, (obj) => parseInt(obj[sort] ? obj[sort] : 0, 10)).reverse(),
            sortby: prevState.sortby === "asc" ? "desc" : "asc",
        }));
    }

    handleSort(sort) {
        this.setState((prevState) => ({
            users: prevState.sortby === "asc" ? sortBy(this.state.users, sort) : sortBy(this.state.users, sort).reverse(),
            sortby: prevState.sortby === "asc" ? "desc" : "asc",
        }));
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            ...this.state,
            [name]: value,
        });
    }

    _handleSelectChange(e) {
        this.setState(
            {
                ...this.state,
                active: e.target.value,
            },
            () => {
                this._getUsers();
            }
        );
    }

    _handleSaveClose() {
        this._getUsers();
        this._handleUser();
    }
    _handleDeviceReload() {
        this.setState({
            ...this.state,
            isDeviceConnect: !this.state.isDeviceConnect,
        });
        this._getUsers();
    }

    _handleDeviceConnect(e, userid) {
        //if (e) e.preventDefault();
        this.setState({
            ...this.state,
            selectedUserid: userid ? userid : "",
            isDeviceConnect: !this.state.isDeviceConnect,
        });
    }

    _handleDeviceDisConnect(e, userid, deviceid) {
        // this.props.dispatch(
        //     openAlertDialog(Constants.Message.confirm.device_disconnect, false, "", "확인", "확인", "취소", this._setDeviceDisconnect(userid, deviceid), false)
        // );

        this.props.setUserDeviceConnect({ connect: "N", userid: userid, deviceid: deviceid }, (error, result) => {
            if (result) {
                this._getUsers();
            }
        });
    }
    _setDeviceDisconnect(userid, deviceid) {
        //alert(userid);
        // this.props.setUserDeviceConnect({ connect: "N", userid: userid, deviceid: deviceid }, (error, result) => {
        //     if (result) {
        //         this._getUsers();
        //     }
        // });
    }
    _handleUser() {
        this.setState({
            ...this.state,
            isUser: !this.state.isUser,
        });
    }
    _handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber,
            currentPage: pageNumber,
        });
    }
    componentDidMount() {
        this._getUsers();
    }

    _getUsers() {
        const { active, searchStr, sort } = this.state;
        this.props.getUsers(
            {
                active: active,
                searchStr: searchStr,
                sort: sort,
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        users: result,
                    });
                }
            }
        );
    }
    render() {
        const apipaginate = commonUtils.dataPaging(this.state.users, this.state.currentPage, this.state.pageSize);
        return (
            <Aux>
                <div className="main_content">
                    <div className="page_content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page_title_box">
                                        <h4>{i18n.t("menu_device_match")}</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form_box">
                                                        <div className="form-group">
                                                            <select
                                                                className="form-control"
                                                                name="active"
                                                                value={this.state.active}
                                                                onChange={this._handleSelectChange}
                                                            >
                                                                <option value="A">All</option>
                                                                <option value="Y">Connected</option>
                                                                <option value="N">Disconnected</option>
                                                            </select>
                                                        </div>
                                                        &nbsp;
                                                        <div className="input-group" style={{ width: "300px" }}>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={i18n.t("label_usernm")}
                                                                name="searchStr"
                                                                value={this.state.searchStr}
                                                                onChange={this._handleChange}
                                                            />
                                                            <div className="input-group-append">
                                                                <button className="btn btn-secondary btn_ico" type="button" onClick={this._getUsers}>
                                                                    <i className="bx bx-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="table-responsive">
                                                <table className="table table-centered table-nowrap mb-0" id="sort">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col">
                                                                {i18n.t("label_userid")}
                                                                <i className="fa fa-sort-amount-asc sort-icon" onClick={() => this.handleSort("userid")}></i>
                                                            </th>
                                                            <th scope="col">
                                                                {i18n.t("label_usernm")}
                                                                <i className="fa fa-sort-amount-asc sort-icon" onClick={() => this.handleSort("usernm")}></i>
                                                            </th>

                                                            <th scope="col">
                                                                {i18n.t("label_deviceid")}
                                                                <i className="fa fa-sort-amount-asc sort-icon" onClick={() => this.handleSort("deviceid")}></i>
                                                            </th>

                                                            <th scope="col">
                                                                {i18n.t("label_devicecnt")}
                                                                <i
                                                                    className="fa fa-sort-amount-asc sort-icon"
                                                                    onClick={() => this._handleNumberSort("devicecnt")}
                                                                ></i>
                                                            </th>
                                                            <th scope="col">
                                                                {i18n.t("label_devicestatus")}
                                                                <i className="fa fa-sort-amount-asc sort-icon" onClick={() => this.handleSort("useryn")}></i>
                                                            </th>
                                                            <th scope="col">
                                                                {i18n.t("label_created")}
                                                                <i
                                                                    className="fa fa-sort-amount-asc sort-icon"
                                                                    onClick={() => this.handleSort("connectedtime")}
                                                                ></i>
                                                            </th>
                                                            <th scope="col">
                                                                {i18n.t("label_lastupdate")}
                                                                <i
                                                                    className="fa fa-sort-amount-asc sort-icon"
                                                                    onClick={() => this.handleSort("lastupdated")}
                                                                ></i>
                                                            </th>
                                                            {/* <th scope="col"></th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.users &&
                                                            apipaginate.map((data) => (
                                                                <tr className="col-12" key={data.userid}>
                                                                    <td>{data.userid} </td>
                                                                    <td>{data.usernm}</td>

                                                                    <td>{data.useyn === "Y" && data.deviceid}</td>
                                                                    <td>
                                                                        {data.devicecnt > 0 ? (
                                                                            <button
                                                                                className={data.devicecnt > 1 ? "btn btn-info" : "btn btn-default"}
                                                                                style={{ fontWeight: "bold", fontSize: "12px" }}
                                                                                onClick={() => this._handleUserDevice(data.userid, data.devicecnt)}
                                                                            >
                                                                                {data.devicecnt}
                                                                            </button>
                                                                        ) : (
                                                                            data.devicecnt
                                                                        )}
                                                                    </td>
                                                                    <td className="status">
                                                                        {data.useyn === "Y" && <span className="badge status blue">Connected</span>}
                                                                    </td>

                                                                    <td style={{ fontFamily: "verdana" }}>
                                                                        {data.useyn === "Y" && commonUtils.localTimezoneDate2(data.connectedtime)}
                                                                    </td>
                                                                    <td style={{ fontFamily: "verdana" }}>
                                                                        {data.lastupdated && commonUtils.localTimezoneDate2(data.lastupdated)}
                                                                    </td>
                                                                    {/* <td style={{ fontWeight: "", fontFamily: "verdana" }}>
                                                                        {data.useyn === "Y" && data.days && data.days > 0 && data.days + "d "}
                                                                        {data.useyn === "Y" && data.hours && data.hours > 0 && data.hours + "h "}
                                                                        {data.useyn === "Y" && data.minutes && data.minutes > 0 && data.minutes + "m"}
                                                                    </td> */}
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                                {this.state.users.length > 0 && (
                                                    <div className="data_page">
                                                        <Pagination
                                                            activePage={this.state.activePage}
                                                            itemsCountPerPage={20}
                                                            totalItemsCount={this.state.users.length}
                                                            pageRangeDisplayed={10}
                                                            onChange={this._handlePageChange.bind(this)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.isUser && <User open={this.state.isUser} onClose={this._handleUser} onSave={this._handleSaveClose} />}
                {this.state.isDeviceConnect && (
                    <DeviceConnect
                        open={this.state.isDeviceConnect}
                        onClose={this._handleDeviceConnect}
                        onSave={this._handleDeviceReload}
                        selectedUserid={this.state.selectedUserid}
                    />
                )}
                {this.state.isDevices && <UserDevice open={this.state.isDevices} onClose={this._handleDevice} userid={this.state.selectedUserid} />}
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setUserDeviceConnect,
            getUsers,
            dispatch,
        },
        dispatch
    );

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(UserManagement);
export { connectedPage as UserManagement };
// export default connect(mapStateToProps, mapDispatchToProps)(windowSize(UserManagement));
