/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setGateway } from "../_services";
// import { Constants } from "../_constants/constants";
import { openAlertDialog } from "../_actions";
import { Modal } from "react-bootstrap";
import i18n from "../i18n";
class Gateway extends Component {
    constructor(props) {
        super(props);

        this.state = {
            gatewayid: "",
            gatewaynm: "",
            description: "",
        };

        this._handleOnClose = this._handleOnClose.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._handleRequest = this._handleRequest.bind(this);
    }

    _handleRequest(e) {
        e.preventDefault();
        const { gatewayid } = this.state;
        if (!gatewayid) {
            this.props.dispatch(openAlertDialog(i18n.t("msg_alert_atozid"), false, "check", false, null, null, null, null));
            return;
        }
        const session = JSON.parse(localStorage.getItem("session"));
        const device = {
            tenantid: session.tenantid,
            gatewayid: this.state.gatewayid,
            gatewaynm: this.state.gatewaynm,
            description: this.state.description,
        };

        this.props.setGateway(device, (error, result) => {
            if (result) {
                //this.props.dispatch(openAlertDialog("OK", false, "information", false, null, null, null, null));
                this._handleSaveClose();
            }
        });
    }

    _handleSaveClose() {
        this.props.onSave();
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
    }

    componentDidMount() {}

    _handleOnClose() {
        this.props.onClose();
    }

    render() {
        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={() => {
                        this._handleOnClose();
                    }}
                    dialogClassName="modal-dialog modal-dialog-centered sz_xlg"
                    backdrop="static"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">AtoZ</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    this._handleOnClose();
                                }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <form className="form-horizontal">
                                <div className="form-group">
                                    <label className="required">{i18n.t("label_atozid")}</label>
                                    <input className="form-control " type="text" value={this.state.gatewayid} onChange={this._handleChange} name="gatewayid" />
                                </div>
                                <div className="form-group">
                                    <label className="required">{i18n.t("label_atoznm")}</label>
                                    <input className="form-control " type="text" value={this.state.gatewaynm} onChange={this._handleChange} name="gatewaynm" />
                                </div>
                                <div className="form-group">
                                    <label className="required">{i18n.t("label_description")}</label>
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        value={this.state.description}
                                        onChange={this._handleChange}
                                        name="description"
                                    ></textarea>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={(e) => {
                                    this._handleRequest(e);
                                }}
                            >
                                {i18n.t("label_btn_save")}
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => {
                                    this._handleOnClose();
                                }}
                            >
                                {i18n.t("label_btn_cancel")}
                            </button>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setGateway,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(Gateway);
export { connectedPage as Gateway };
