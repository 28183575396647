/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Pagination from "react-js-pagination";
import { commonUtils } from "_helpers";
import { getDevices, getModels, deleteDevice } from "_services";
import { Device } from "./Device";
import Aux from "../hoc/_Aux";
import i18n from "../i18n";
class DeviceManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDevice: false,
            model: "A",
            devicetype: "A",
            searchStr: "",
            sort: "createdtime",
            activePage: 1,
            pageSize: 14,
            currentPage: 1,
            devices: [],
            models: [],
            devicetypes: [],
        };
        this._handleDevice = this._handleDevice.bind(this);
        this._handleSaveClose = this._handleSaveClose.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._getDevices = this._getDevices.bind(this);
    }
    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
    }

    _handleSaveClose() {
        this._getDevices();
        this._handleDevice();
    }
    _handleDevice() {
        this.setState({
            ...this.state,
            isDevice: !this.state.isDevice,
        });
    }
    _handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber,
            currentPage: pageNumber,
        });
    }
    componentDidMount() {
        //this._getModels();
        this._getDevices();
    }

    _handleDeleteDevice(deviceid) {
        this.props.deleteDevice({ deviceid: deviceid }, (error, result) => {
            if (result) {
                this._getDevices();
            }
        });
    }

    _getModels() {
        this.props.getModels({}, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    models: result.filter((e) => e.type === "M"),
                    devicetypes: result.filter((e) => e.type === "T"),
                });
            }
        });
    }

    _getDevices() {
        const { devicetype, model, searchStr, sort } = this.state;
        this.props.getDevices(
            {
                model: model,
                devicetype: devicetype,
                searchStr: searchStr,
                sort: sort,
            },
            (error, result) => {
                if (result) {
                    //console.log("result===", result);
                    this.setState({
                        ...this.state,
                        devices: result,
                    });
                }
            }
        );
    }
    render() {
        const apipaginate = commonUtils.dataPaging(this.state.devices, this.state.currentPage, this.state.pageSize);
        return (
            <Aux>
                <div className="main_content">
                    <div className="page_content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page_title_box">
                                        <h4>{i18n.t("menu_device_mng")}</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form_box">
                                                        {/* <div className="form-group">
                                                            <select
                                                                className="form-control "
                                                                name="model"
                                                                value={this.state.model}
                                                                onChange={this._handleChange}
                                                            >
                                                                <option value="A">{i18n.t("label_devicemodel")}</option>
                                                                {this.state.models &&
                                                                    this.state.models.map((data) => (
                                                                        <option key={data.mdid} value={data.modelnm}>
                                                                            {data.modelnm}
                                                                        </option>
                                                                    ))}
                                                            </select>{" "}
                                                            &nbsp;
                                                            <select
                                                                className="form-control "
                                                                name="devicetype"
                                                                value={this.state.devicetype}
                                                                onChange={this._handleChange}
                                                            >
                                                                <option value="A">{i18n.t("label_devicetype")}</option>
                                                                {this.state.devicetypes &&
                                                                    this.state.devicetypes.map((data) => (
                                                                        <option key={data.mdid} value={data.modelnm}>
                                                                            {data.modelnm}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </div> &nbsp; */}

                                                        <div className="input-group" style={{ width: "300px" }}>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={i18n.t("label_devicenm")}
                                                                name="searchStr"
                                                                value={this.state.searchStr}
                                                                onChange={this._handleChange}
                                                            />
                                                            <div className="input-group-append">
                                                                <button type="button" className="btn btn-secondary btn_ico" onClick={this._getDevices}>
                                                                    <i className="bx bx-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="col-4">
                                                    <div className="text-sm-right">
                                                        <button type="button" className="btn btn-success" onClick={this._handleDevice}>
                                                            <i className="bx bx-plus"></i> {i18n.t("label_btn_adddevice")}
                                                        </button>
                                                    </div>
                                                </div> */}
                                            </div>

                                            <div className="table-responsive">
                                                <table className="table table-centered table-nowrap">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th>{i18n.t("label_devicemodel")}</th>
                                                            <th>{i18n.t("label_devicetype")}</th>
                                                            <th>{i18n.t("label_deviceid")}</th>
                                                            <th>{i18n.t("label_devicenm")}</th>
                                                            <th>{i18n.t("label_devicestatus")}</th>
                                                            <th>{i18n.t("label_deviceuser")}</th>
                                                            <th>{i18n.t("label_created")}</th>
                                                            <th>{i18n.t("label_elapsed")}</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.devices &&
                                                            apipaginate.map((data) => (
                                                                <tr className="col-12" key={data.deviceid}>
                                                                    <td>{data.model}</td>
                                                                    <td>{data.devicetype}</td>
                                                                    <td>{data.deviceid}</td>
                                                                    <td>{data.devicenm}</td>
                                                                    <td className="status">
                                                                        {data.useyn === "Y" && <span className="badge status blue">Active</span>}
                                                                        {data.useyn === "N" && <span className="badge status">Non Active</span>}
                                                                    </td>
                                                                    <td>
                                                                        {/* {data.userid === "N" && <a className="text-white label bg-c-gray f-10">connect</a>} */}
                                                                        {data.userid !== "N" && data.usernm}
                                                                    </td>
                                                                    <td style={{ fontFamily: "verdana" }}>{data.useyn === "Y" && data.createdtime}</td>
                                                                    <td style={{ fontFamily: "verdana" }}>
                                                                        {data.useyn === "Y" && data.days && data.days > 0 && data.days + "d "}
                                                                        {data.useyn === "Y" && data.hours && data.hours > 0 && data.hours + "h "}
                                                                        {data.useyn === "Y" && data.minutes && data.minutes > 0 && data.minutes + "m"}
                                                                    </td>
                                                                    <td className="btn_td">
                                                                        <button
                                                                            type="button"
                                                                            title="Delete"
                                                                            className="ico_btn delete"
                                                                            onClick={() => this._handleDeleteDevice(data.deviceid)}
                                                                        >
                                                                            <i className="bx bxs-trash"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                                {this.state.devices.length > 0 && (
                                                    <div className="text-center data_page">
                                                        <Pagination
                                                            activePage={this.state.activePage}
                                                            itemsCountPerPage={14}
                                                            totalItemsCount={this.state.devices.length}
                                                            pageRangeDisplayed={10}
                                                            onChange={this._handlePageChange.bind(this)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.isDevice && <Device open={this.state.isDevice} onClose={this._handleDevice} onSave={this._handleSaveClose} />}
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            deleteDevice,
            getModels,
            getDevices,
            dispatch,
        },
        dispatch
    );

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(DeviceManagement);
export { connectedPage as DeviceManagement };
