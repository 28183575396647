/* eslint-disable no-extend-native */
import i18n from "../../i18n";
const menus = [
    {
        id: "dashboard",
        title: i18n.t("menu_dashboard"),
        type: "item",
        path: "/dashboard",
        icon: "bx bx-home",
        class: "dashboard",
        show: true,
    },
    {
        id: "Group Dashboard",
        title: i18n.t("menu_usergroup_monitor"),
        type: "item",
        icon: "bx bx-group",
        path: "/groupdashboard",
        class: "groupdashboard",
        show: true,
    },
    // {
    //     id: "Group Dashboard",
    //     title: i18n.t("menu_user_monthly"),
    //     type: "item",
    //     icon: "bx bx-collection",
    //     path: "/groupdashboard",
    //     class: "groupdashboard",
    //     show: true,
    // },
    {
        id: "User Group",
        title: i18n.t("menu_usergroup_mng"),
        type: "item",
        icon: "bx bx-group",
        path: "/group",
        class: "group",
        show: true,
    },
    // {
    //     id: "device",
    //     title: i18n.t("menu_device_mng"),
    //     type: "item",
    //     icon: "bx bx-server",
    //     path: "/device",
    //     class: "device",
    //     show: true,
    // },
    {
        id: "user",
        title: i18n.t("menu_device_match"),
        type: "item",
        path: "/user",
        icon: "bx bx-user",
        class: "user_device",
        show: true,
    },
    // {
    //     id: "gateway",
    //     title: i18n.t("menu_atoz_testing"),
    //     type: "item",
    //     icon: "bx bx-station",
    //     path: "/gateway",
    //     class: "gateway",
    //     show: true,
    // },
    {
        id: "settings",
        title: i18n.t("menu_setting"),
        type: "item",
        icon: "bx bx-cog",
        path: "/settings",
        class: "setting",
        show: true,
    },
];

export function getMenus() {
    const menuList = menus;
    return menuList;
}
