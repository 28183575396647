/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logout } from "_services";
// import { clearStore } from "_actions";
// import { clearMenus } from "./menus";

class AppHeader extends Component {
    constructor(props) {
        super(props);

        this.userInfoRef = React.createRef();
        this.toggleUserInfoRef = React.createRef();

        this.state = {
            toggleUserInfo: false,
        };

        this._handleLogout = this._handleLogout.bind(this);
    }

    componentDidMount() {}

    componentDidUpdate() {
        if (this.state.toggleUserInfo) {
            window.$("#user-info-dialog").slideDown(200);
        } else {
            window.$("#user-info-dialog").slideUp(200);
        }
    }

    _handleLogout(e) {
        e.preventDefault();
        //this.props.dispatch(clearStore());
        this.props.logout();
        this.props.history.push("/login");
    }
    render() {
        const session = JSON.parse(localStorage.getItem("session"));
        return (
            <header className="gnb_area">
                <div className="navbar_header">
                    <div className="d-flex">
                        <div className="navbar_brand_box">
                            <a className="logo logo_dark">
                                {session.tenantid === "aiblab" && <img src="../common/images/logo2.png" alt="" height="24" />}
                                {session.tenantid !== "aiblab" && <img src="../common/images/logo.png" alt="" height="50" />}
                            </a>
                            <a className="logo logo_light">
                                {session.tenantid === "aiblab" && <img src="../common/images/logo_light2.png" alt="" height="22" />}
                                {session.tenantid !== "aiblab" && <img src="../common/images/logo_light.png" alt="" height="22" />}
                            </a>
                        </div>

                        <button type="button" className="header_item">
                            <i className="btn_header"></i>
                        </button>
                    </div>
                    <div className="d-flex">
                        <div className="util">
                            <div className="user">
                                <img src="../common/images/ico_profile.png" alt="" height="30" />
                                <span className="name"> {session.userid}</span>
                            </div>
                            {/* <button type="button" className="btn btn-info">
                                <span className="glyphicon glyphicon-log-out"></span>
                            </button> */}
                            <button type="button" className="logout" onClick={this._handleLogout}>
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            logout,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(AppHeader);
export { connectedPage as AppHeader };
