import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import moment from "moment-timezone";
import Aux from "../hoc/_Aux";

class UserTemperatureLog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mapsLoaded: false,
            map: null,
            maps: null,
        };
        this._handleOnClose = this._handleOnClose.bind(this);
    }

    _handleOnClose() {
        this.props.onClose();
    }

    componentDidMount() {}

    render() {
        return (
            <Aux>
                <Modal
                    show={this.props.open}
                    onHide={() => {
                        this._handleOnClose();
                    }}
                    dialogClassName="modal-dialog modal-dialog-centered sz_xlg"
                    backdrop="static"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                {this.props.selectedUser} {this.props.temperatures && <span>({this.props.temperatures.length})</span>}
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    this._handleOnClose();
                                }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body scroll">
                            <div className="table-responsive table-centered table-fixed mb-0">
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>Time</th>
                                            <th>Temperature</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.temperatures &&
                                            this.props.temperatures.map((data) => (
                                                <tr className="col-12" key={data.time}>
                                                    <td>{moment.tz(data.time, "YYYY-MM-DD HH:mm:ss", moment.tz.guess()).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                    <td>{data.temperature.toFixed(1)}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal>
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(UserTemperatureLog);
export { connectedPage as UserTemperatureLog };
