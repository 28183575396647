import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GoogleMap from "google-map-react";
import { Modal } from "react-bootstrap";
// import Marker from "./Marker";
import MarkerClusterer from "@google/markerclustererplus";
// import Polyline from "./Polyline";
import Aux from "../hoc/_Aux";

class UserPopMap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mapsLoaded: false,
            map: null,
            maps: null,
        };
        this._handleOnClose = this._handleOnClose.bind(this);
    }

    onMapLoaded(map, maps) {
        this.googleMapRef = map;
        this.googleRef = maps;

        const locations = [];
        for (let marker of this.props.geolocations) {
            locations.push({ lat: marker.lat, lng: marker.lng });
        }

        const markers =
            locations &&
            locations.map((location) => {
                return new this.googleRef.Marker({ position: location });
            });

        new MarkerClusterer(map, markers, {
            imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
            gridSize: 10, //군집 갯수
            minimumClusterSize: 2, //마커의 최소갯수
        });

        this.setState({
            ...this.state,
            mapsLoaded: true,
            map: map,
            maps: maps,
        });
    }

    fitBounds(map, maps) {
        var bounds = new maps.LatLngBounds();
        for (let marker of this.props.geolocations) {
            //console.log("marker.geohash", marker.geohash);
            bounds.extend(new maps.LatLng(marker.lat, marker.lng));
        }
        map.fitBounds(bounds);
    }

    afterMapLoadChanges() {
        // return (
        //     <div style={{ display: "none" }}>
        //         {this.state.map && <Polyline map={this.state.map} maps={this.state.maps} markers={this.props.geolocations} />}
        //     </div>
        // );
    }

    _handleOnClose() {
        this.props.onClose();
    }

    componentDidMount() {}

    render() {
        const { defaultLat, defaultLng } = this.props;
        return (
            <Aux>
                <Modal
                    show={this.props.open}
                    onHide={() => {
                        this._handleOnClose();
                    }}
                    dialogClassName="modal-dialog modal-dialog-centered basic_modal modal-lg"
                    backdrop="static"
                >
                    <div className="modal-header">
                        <h5 className="modal-title">{this.props.selectedUser}</h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                this._handleOnClose();
                            }}
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div style={{ height: "480px", width: "100%" }}>
                            <GoogleMap
                                bootstrapURLKeys={{ key: "AIzaSyC3N2rnRIoLNjjKDzze8JUmLa7QJlT2YRY" }}
                                defaultCenter={[defaultLat, defaultLng]}
                                defaultZoom={14}
                                onGoogleApiLoaded={({ map, maps }) => this.onMapLoaded(map, maps)}
                                yesIWantToUseGoogleMapApiInternals
                            >
                                {/* {this.props.geolocations &&
                                    this.props.geolocations.map((data, index) => <Marker key={index} text={""} lat={data.lat} lng={data.lng} />)} */}

                                {this.state.mapsLoaded ? this.afterMapLoadChanges() : ""}
                            </GoogleMap>
                        </div>
                    </div>
                </Modal>
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(UserPopMap);
export { connectedPage as UserPopMap };
