export const COLLAPSE_MENU = "COLLAPSE_MENU";
export const COLLAPSE_TOGGLE = "COLLAPSE_TOGGLE";
export const FULL_SCREEN = "FULL_SCREEN";
export const FULL_SCREEN_EXIT = "FULL_SCREEN_EXIT";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const NAV_CONTENT_LEAVE = "NAV_CONTENT_LEAVE";
export const NAV_COLLAPSE_LEAVE = "NAV_COLLAPSE_LEAVE";

export const GET_DEVICES = "GET_DEVICES";
export const GET_CONNECT_DEVICES = "GET_CONNECT_DEVICES";
export const SET_USER_DEVICE_CONNECT = "SET_USER_DEVICE_CONNECT";
export const SET_DEVICES = "GET_DEVICES";
export const SET_DELETE_DEVICES = "SET_DELETE_DEVICES";

export const GET_MODELS = "GET_MODELS";
export const SET_MODELS = "SET_MODELS";

export const GET_GATEWAYS = "GET_GATEWAYS";
export const SET_GATEWAYS = "SET_GATEWAYS";
export const DEL_GATEWAYS = "DEL_GATEWAYS";

export const GET_USERS = "GET_USERS";
export const SET_USERS = "SET_USERS";

export const GET_USERS_MONITOR = "GET_USERS_MONITOR";
export const GET_USERS_GRAPH = "GET_USERS_GRAPH";
export const GET_USER_MONITOR = "GET_USER_MONITOR";

export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";

export const GET_USERGROUP = "GET_USERGROUP";
export const SET_DELETE_USERGROUP = "SET_DELETE_USERGROUP";
export const SET_ADD_USERGROUP = "SET_ADD_USERGROUP";
export const SET_UP_USERGROUP = "SET_UP_USERGROUP";

export const GET_GROUP_USERS = "GET_GROUP_USERS";
export const GET_GROUP_STATS = "GET_GROUP_STATS";

export const GET_COMMON_CODE = "GET_COMMON_CODE";
export const SET_COMMON_CODE = "SET_COMMON_CODE";

export function getGroupStatsReq() {
    return {
        type: GET_GROUP_STATS,
    };
}

export function setCommonCodeReq() {
    return {
        type: SET_COMMON_CODE,
    };
}

export function getCommonCodeReq() {
    return {
        type: GET_COMMON_CODE,
    };
}

export function deleteDevicesReq() {
    return {
        type: SET_DELETE_DEVICES,
    };
}

export function getGroupUsersReq() {
    return {
        type: GET_GROUP_USERS,
    };
}

export function getUserGroupReq() {
    return {
        type: GET_USERGROUP,
    };
}

export function setUserGroupDeleteReq() {
    return {
        type: SET_DELETE_USERGROUP,
    };
}

export function setUserGroupAddReq() {
    return {
        type: SET_ADD_USERGROUP,
    };
}

export function setUserGroupUpReq() {
    return {
        type: SET_UP_USERGROUP,
    };
}

export function userLoginReq(error) {
    return { type: USER_LOGIN, error };
}

export function userLogout() {
    return { type: USER_LOGOUT };
}

export function getGatewaysReq() {
    return {
        type: GET_GATEWAYS,
    };
}

export function setGatewaysReq() {
    return {
        type: SET_GATEWAYS,
    };
}

export function deleteGatewaysReq() {
    return {
        type: DEL_GATEWAYS,
    };
}

export function getUsersGraphReq() {
    return {
        type: GET_USERS_GRAPH,
    };
}

export function getUsersMonitorReq() {
    return {
        type: GET_USERS_MONITOR,
    };
}

export function getUserMonitorReq() {
    return {
        type: GET_USER_MONITOR,
    };
}

export function setUserDeviceConnectReq() {
    return {
        type: SET_USER_DEVICE_CONNECT,
    };
}

export function getConnectDevicesReq() {
    return {
        type: GET_CONNECT_DEVICES,
    };
}

export function gerUsersReq() {
    return {
        type: GET_USERS,
    };
}

export function setUserReq() {
    return {
        type: SET_USERS,
    };
}

export function getModelsReq() {
    return {
        type: GET_MODELS,
    };
}

export function setModelsReq() {
    return {
        type: SET_MODELS,
    };
}

export function getDevicesReq() {
    return {
        type: GET_DEVICES,
    };
}

export function setDevicesReq() {
    return {
        type: SET_DEVICES,
    };
}
