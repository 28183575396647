import React from "react";
import ReactEcharts from "echarts-for-react";
import echarts from "echarts";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { commonUtils } from "_helpers";
// import { Modal } from "react-bootstrap";
import moment from "moment-timezone";
import Aux from "../hoc/_Aux";

class CompareChartDelta extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUser: this.props.selectedUser,
            interval: this.props.interval,
            option: this.getOption(this.props.temperatures, this.props.interval, this.props.tdisptype),
        };
        this._handleOnClose = this._handleOnClose.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.temperatures !== this.props.temperatures) {
            this.setState({
                ...this.state,
                option: this.getOption(this.props.temperatures, this.props.interval, this.props.tdisptype),
            });
        }
    }

    _handleOnClose() {
        this.props.onClose();
    }

    getOption = (temperatures, interval, tdisptype) => {
        let normalTime = [];
        let normalTemp = [];
        let deltaTime = [];
        let deltaTemp = [];

        for (let temp of temperatures) {
            if (interval === "daily") {
                normalTime.push(commonUtils.localTimezoneTime(temp.times));
                deltaTime.push(commonUtils.localTimezoneTime(temp.times));
            } else {
                normalTime.push(commonUtils.localTimezoneDay(temp.times));
                deltaTime.push(commonUtils.localTimezoneDay(temp.times));
            }
            normalTemp.push(tdisptype === "C" ? temp.temperature : temp.ftemperature);
            deltaTemp.push(temp.delta);
        }

        const option = {
            title: [
                {
                    left: "left",
                    text: "Temperature trend",
                },
                {
                    top: "52%",
                    left: "left",
                    text: "Delta-T trend",
                },
            ],
            tooltip: {
                trigger: "axis",
            },
            xAxis: [
                {
                    data: normalTime,
                    boundaryGap: false,
                },
                {
                    data: deltaTime,
                    boundaryGap: false,
                    gridIndex: 1,
                    minorTick: {
                        show: true,
                    },
                },
            ],
            yAxis: [
                {
                    splitLine: { show: true },
                    axisLabel: {
                        formatter: "{value} °" + tdisptype,
                    },

                    min: function (item) {
                        var diff = item.max - item.min;
                        if (diff === 0) {
                            diff = 1;
                        }
                        return (item.min - diff * 0.2).toFixed(1);
                    },
                },
                {
                    min: -4,
                    max: 4,
                    interval: 1,
                    splitLine: { show: true },
                    gridIndex: 1,
                },
            ],
            grid: [
                {
                    top: 50,
                    left: 50,
                    right: 42,
                    bottom: "60%",
                },
                {
                    left: 50,
                    right: 42,
                    top: "60%",
                },
            ],
            series: [
                {
                    type: "line",
                    showSymbol: true,
                    connectNulls: true,
                    lineStyle: {
                        normal: {
                            color: "#ea6f21",
                            width: 4,
                        },
                    },
                    tooltip: {
                        show: true,
                    },
                    areaStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    {
                                        offset: 0,
                                        color: "rgba(236, 169, 44, 1)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(236, 169, 44,0)",
                                    },
                                ],
                                false
                            ),
                        },
                    },
                    data: normalTemp,
                },
                {
                    type: "line",
                    showSymbol: true,
                    connectNulls: true,
                    lineStyle: {
                        normal: {
                            color: "#ea6f21",
                            width: 4,
                        },
                    },
                    tooltip: {
                        show: true,
                    },
                    areaStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    {
                                        offset: 0,
                                        color: "rgba(236, 169, 44, 1)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(236, 169, 44,0)",
                                    },
                                ],
                                false
                            ),
                        },
                    },
                    data: deltaTemp,
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    //smooth: true,
                },
            ],
        };

        return option;
    };

    render() {
        return (
            <Aux>
                <div className="graph_area">
                    <ReactEcharts notMerge ref="echarts_react" option={this.state.option} style={{ height: 500 }} />
                </div>
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(CompareChartDelta);
export { connectedPage as CompareChartDelta };
