/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserStatsDetail, getUserDeltaStats, getUserTemperatures, getUserTemperaturesPattern, getUserTemperaturesWeeklyPattern } from "_services";
import { Modal } from "react-bootstrap";
import { commonUtils } from "_helpers";
import {
    // CompareChartDelta,
    UserSpo2Chart,
    UserHeartChart,
    UserBreathChart,
    UserStepChart,
    UserBloodChart,
    TemperatureChart,
    TemperaturePatternChart,
    TermperaturePatternWeekly,
    BloodPatternChart,
    BloodPatternWeekly,
} from "_pages";
// import { UserMap } from "./UserMap";
// import { UserChart } from "./UserChart";
import { ExcelDownload } from "./ExcelDownload";
import i18n from "../i18n";
import moment from "moment";
class UserDetailDaily extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userDetail: "",
            startDay: "",
            endDay: "",
            baseDay: moment(),
            isMap: false,
            temperatures: [],
            logtemperatures: [],
            healthpatterns: [],
            healthweeklypatterns: [],
            isExcelDownload: false,
            patternType: "A",
            selectedTab: "temperature",
        };

        this._handleOnClose = this._handleOnClose.bind(this);
        this._handleExcelDownload = this._handleExcelDownload.bind(this);
        this._handleDate = this._handleDate.bind(this);
        this._handleDateInterval = this._handleDateInterval.bind(this);
        this._handlePattern = this._handlePattern.bind(this);
        this.__handleTabMenu = this._handleTabMenu.bind(this);
    }

    _handleTabMenu(tab) {
        this.setState({
            selectedTab: tab,
        });
    }

    _handlePattern(e) {
        this.setState({
            patternType: e.target.value,
        });
    }
    _handleDate(flag) {
        const { interval } = this.props;
        this.setState(
            {
                ...this.state,
                baseDay: commonUtils.statsDateRange(this.state.baseDay, interval, flag, "start"),
                startDay: commonUtils.statsDateRange(this.state.baseDay, interval, flag, "start"),
                endDay: commonUtils.statsDateRange(this.state.baseDay, interval, flag, "end"),
            },
            () => {
                this._getUserTemperatures();
                this._getUserPatterns();
                this._getUserWeeklyPattern();
            }
        );
    }

    _handleDateInterval(interval) {
        this.setState(
            {
                ...this.state,
                baseDay: moment(),
                startDay: commonUtils.statsDateRange(moment(), interval, "now", "start"),
                endDay: commonUtils.statsDateRange(moment(), interval, "now", "end"),
                trangeYn: "N",
            },
            () => {
                this._getUserTemperatures();
                this._getUserPatterns();
                this._getUserWeeklyPattern();
            }
        );
    }

    componentDidMount() {
        this.setState(
            {
                startDay: this.props.startDay,
                endDay: this.props.endDay,
            },
            () => {
                this._getUserInfo();
            }
        );
    }

    _getUserInfo() {
        this.props.getUserStatsDetail({ userid: this.props.userid }, (error, result) => {
            if (result) {
                this._getUserTemperatures();
                this._getUserPatterns();
                this._getUserWeeklyPattern();
                this.setState({
                    ...this.state,
                    userDetail: result,
                });
            }
        });
    }

    _getUserPatterns() {
        this.props.getUserTemperaturesPattern(
            {
                interval: this.props.interval,
                from: this.state.startDay,
                to: this.state.endDay,
                userid: this.props.userid,
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        healthpatterns: result,
                    });
                }
            }
        );
    }

    _getUserWeeklyPattern() {
        this.props.getUserTemperaturesWeeklyPattern(
            {
                interval: this.props.interval,
                from: this.state.startDay,
                to: this.state.endDay,
                userid: this.props.userid,
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        healthweeklypatterns: result,
                    });
                }
            }
        );
    }

    _getUserTemperatures() {
        this.props.getUserDeltaStats(
            {
                interval: this.props.interval,
                from: this.state.startDay,
                to: this.state.endDay,
                userid: this.props.userid,
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        temperatures: result,
                        logtemperatures: result,
                    });
                }
            }
        );
    }

    _handleOnClose() {
        this.props.onClose();
    }

    _handleExcelDownload() {
        const logs = [];
        for (let temp of this.state.temperatures) {
            const logValue = {
                times: this.props.interval === "daily" ? commonUtils.localTimezoneTime(temp.times) : commonUtils.localTimezoneDay(temp.times),
                tmax: temp.temperature,
                oxygenlast: temp.oxygen,
                heartlast: temp.heart,
                sbloodlast: temp.sblood,
                dbloodlast: temp.dblood,
                breathlast: temp.breath,
                steplast: temp.step,
            };
            logs.push(logValue);
        }
        this.setState({
            ...this.state,
            isExcelDownload: !this.state.isExcelDownload,
            logtemperatures: logs,
        });
    }

    render() {
        const { userDetail } = this.state;
        const session = JSON.parse(localStorage.getItem("session"));

        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={() => {
                        this._handleOnClose();
                    }}
                    dialogClassName="modal-dialog modal-dialog-centered modal-xl"
                    backdrop="static"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            {/* <h5 className="modal-title">{i18n.t("label_userinfo")}</h5> */}
                            {this.props.interval === "daily" ? (
                                <div className="page_btn_box" style={{ paddingBottom: "0px" }}>
                                    <div className="btn-group" role="group">
                                        <button type="button" className="btn" onClick={() => this._handleDate("prev")}>
                                            {i18n.t("label_btn_prev")}
                                        </button>
                                        <button type="button" className="btn" onClick={() => this._handleDate("next")}>
                                            {i18n.t("label_btn_next")}
                                        </button>
                                    </div>
                                    <div className="btn-group">
                                        <button type="button" className="btn" onClick={() => this._handleDateInterval("daily")}>
                                            {i18n.t("label_btn_today")}
                                        </button>
                                    </div>
                                    <div className="date_text" style={{ fontFamily: "verdana" }}>
                                        {this.props.interval === "daily" && this.state.startDay}
                                    </div>
                                </div>
                            ) : (
                                <h5 className="modal-title">{i18n.t("label_userinfo")}</h5>
                            )}
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    this._handleOnClose();
                                }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="table-responsive">
                                <table className="table table_row table-centered table-nowrap">
                                    <tbody>
                                        <tr>
                                            <th>{i18n.t("label_username")}</th>
                                            <td>{userDetail.usernm}</td>
                                            <th>{i18n.t("label_group")}</th>
                                            <td>{userDetail.grpnm}</td>
                                        </tr>
                                        <tr>
                                            <th>{i18n.t("label_devicemodel")}</th>
                                            <td>{userDetail.devicetype}</td>
                                            <th>{i18n.t("label_deviceid")}</th>
                                            <td>{userDetail.deviceid}</td>
                                        </tr>
                                        <tr>
                                            <th>{i18n.t("label_lastupdate")}</th>
                                            <td>{commonUtils.localTimezoneDate2(userDetail.lastupdated)}</td>
                                            <th>{i18n.t("label_totalrecord")}</th>
                                            <td>{userDetail.accucnt}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <ul className="nav nav-tabs nav-tabs-custom" role="tablist">
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        data-toggle="tab"
                                        href="#temperature"
                                        role="tab"
                                        aria-selected="true"
                                        onClick={() => this._handleTabMenu("temperature")}
                                    >
                                        {i18n.t("label_temperature")}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        data-toggle="tab"
                                        href="#spo2"
                                        role="tab"
                                        aria-selected="true"
                                        onClick={() => this._handleTabMenu("spo2")}
                                    >
                                        {i18n.t("label_oxygen")}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        data-toggle="tab"
                                        href="#heart"
                                        role="tab"
                                        aria-selected="true"
                                        onClick={() => this._handleTabMenu("heart")}
                                    >
                                        {i18n.t("label_heart")}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        data-toggle="tab"
                                        href="#blood"
                                        role="tab"
                                        aria-selected="true"
                                        onClick={() => this._handleTabMenu("blood")}
                                    >
                                        {i18n.t("label_blood")}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        data-toggle="tab"
                                        href="#respiratory"
                                        role="tab"
                                        aria-selected="true"
                                        onClick={() => this._handleTabMenu("respiratory")}
                                    >
                                        {i18n.t("label_respiratory")}
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a
                                        className="nav-link"
                                        data-toggle="tab"
                                        href="#step"
                                        role="tab"
                                        aria-selected="true"
                                        onClick={() => this._handleTabMenu("step")}
                                    >
                                        {i18n.t("label_step")}
                                    </a>
                                </li>
                            </ul>
                            <div className="tab_btn_box">
                                <div className="tab_btn">
                                    <button type="button" className="btn btn-success" onClick={() => this._handleExcelDownload()}>
                                        <i className="bx bx-download"></i> Data Log Download
                                    </button>
                                </div>
                            </div>
                            <div className="tab-content graph_box">
                                {/* <div className="tab-pane graph_area active" id="temperature" role="tabpanel">
                                    <CompareChartDelta temperatures={this.state.temperatures} interval={this.props.interval} tdisptype={session.tdisptype} />
                                </div> */}
                                <div className="tab-pane graph_area active" id="temperature" role="tabpanel">
                                    <TemperatureChart temperatures={this.state.temperatures} interval={this.props.interval} tdisptype={session.tdisptype} />
                                </div>
                                <div className="tab-pane map_area" id="spo2" role="tabpanel">
                                    <UserSpo2Chart temperatures={this.state.temperatures} interval={this.props.interval} />
                                </div>
                                <div className="tab-pane map_area" id="heart" role="tabpanel">
                                    <UserHeartChart temperatures={this.state.temperatures} interval={this.props.interval} />
                                </div>
                                <div className="tab-pane map_area" id="blood" role="tabpanel">
                                    <UserBloodChart temperatures={this.state.temperatures} interval={this.props.interval} />
                                </div>
                                <div className="tab-pane map_area" id="respiratory" role="tabpanel">
                                    <UserBreathChart temperatures={this.state.temperatures} interval={this.props.interval} />
                                </div>
                                <div className="tab-pane map_area" id="step" role="tabpanel">
                                    <UserStepChart temperatures={this.state.temperatures} interval={this.props.interval} />
                                </div>
                                <div className="row">
                                    <div className="" style={{ display: "flex", padding: "0px 0px 30px 18px" }}>
                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                id="patternA"
                                                value="A"
                                                checked={this.state.patternType === "A" ? true : false}
                                                onChange={this._handlePattern}
                                            />
                                            <label className="form-check-label" htmlFor="patternA" style={{ cursor: "pointer" }}>
                                                과거 7일간 패턴
                                            </label>
                                        </div>

                                        <div className="form-check form-check-inline">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                id="patternB"
                                                name="patternB"
                                                value="B"
                                                checked={this.state.patternType === "B" ? true : false}
                                                onChange={this._handlePattern}
                                            />
                                            <label className="form-check-label" htmlFor="patternB" style={{ cursor: "pointer" }}>
                                                과거 같은 요일 4주 패턴
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {this.state.patternType === "A" && (
                                    <TemperaturePatternChart
                                        healthpatterns={this.state.healthpatterns}
                                        interval={this.props.interval}
                                        startDay={this.state.startDay}
                                        tdisptype={session.tdisptype}
                                        tabtype={this.state.selectedTab}
                                    />
                                )}
                                {this.state.patternType === "A" && this.state.selectedTab === "blood" && (
                                    <BloodPatternChart
                                        healthpatterns={this.state.healthpatterns}
                                        interval={this.props.interval}
                                        startDay={this.state.startDay}
                                        tdisptype={session.tdisptype}
                                        tabtype={this.state.selectedTab}
                                    />
                                )}
                                {this.state.patternType === "B" && (
                                    <TermperaturePatternWeekly
                                        healthpatterns={this.state.healthweeklypatterns}
                                        interval={this.props.interval}
                                        startDay={this.state.startDay}
                                        tdisptype={session.tdisptype}
                                        tabtype={this.state.selectedTab}
                                    />
                                )}
                                {this.state.patternType === "B" && this.state.selectedTab === "blood" && (
                                    <BloodPatternWeekly
                                        healthpatterns={this.state.healthpatterns}
                                        interval={this.props.interval}
                                        startDay={this.state.startDay}
                                        tdisptype={session.tdisptype}
                                        tabtype={this.state.selectedTab}
                                    />
                                )}
                            </div>

                            <div className="table-responsive maxheight200">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>{i18n.t("label_time")}</th>
                                            {this.props.interval === "daily" ? (
                                                <th className="text-center">
                                                    {i18n.t("label_temperature")} ({" °" + session.tdisptype})
                                                </th>
                                            ) : (
                                                <th className="text-center">
                                                    {i18n.t("label_avg")} ({" °" + session.tdisptype})
                                                </th>
                                            )}
                                            <th className="text-center">{i18n.t("label_deltat")} (△T)</th>

                                            <th className="text-center">{i18n.t("label_oxygen")}</th>
                                            <th className="text-center">{i18n.t("label_heart")}</th>
                                            <th className="text-center">{i18n.t("label_blood")}</th>
                                            <th className="text-center">{i18n.t("label_respiratory")}</th>
                                            <th className="text-center">{i18n.t("label_step")}</th>
                                            {this.props.interval === "daily" ? (
                                                <th className="text-center">{i18n.t("label_only-battery")}</th>
                                            ) : (
                                                <th className="text-center">{i18n.t("label_nom")}</th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.temperatures &&
                                            this.state.temperatures.map((data, index) => {
                                                return data.disp !== null ? (
                                                    <tr className="col-12" key={index}>
                                                        <td>
                                                            {this.props.interval === "daily"
                                                                ? commonUtils.localTimezoneTime(data.times)
                                                                : commonUtils.localTimezoneDay(data.times) + "일"}
                                                        </td>
                                                        {this.props.interval === "daily" ? (
                                                            <td className="text-center">{session.tdisptype === "C" ? data.temperature : data.ftemperature}</td>
                                                        ) : (
                                                            <td className="text-center">{session.tdisptype === "C" ? data.tavg : data.tfavg}</td>
                                                        )}

                                                        <td className="text-center">{data.delta}</td>
                                                        <td className="text-center">{data.oxygen}</td>
                                                        <td className="text-center">{data.heart}</td>
                                                        <td className="text-center">
                                                            {data.sblood}/{data.dblood}
                                                        </td>
                                                        <td className="text-center">{data.breath}</td>
                                                        <td className="text-center">{data.step}</td>

                                                        {this.props.interval === "daily" ? (
                                                            <td className="text-center">{data.battery}</td>
                                                        ) : (
                                                            <td className="text-center">{data.tcnt}</td>
                                                        )}
                                                    </tr>
                                                ) : (
                                                    ""
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal>

                {this.state.isExcelDownload && (
                    <ExcelDownload
                        temperatures={this.state.logtemperatures}
                        usernm={this.state.userDetail.usernm}
                        startDay={this.props.startDay}
                        endDay={this.props.endDay}
                    />
                )}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getUserTemperatures,
            getUserStatsDetail,
            getUserDeltaStats,
            getUserTemperaturesPattern,
            getUserTemperaturesWeeklyPattern,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(UserDetailDaily);
export { connectedPage as UserDetailDaily };
