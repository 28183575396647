/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setTenantAdmin, updateTenantAdmin } from "../_services";
import { openAlertDialog } from "../_actions";
import { Modal } from "react-bootstrap";
import i18n from "../i18n";
class TenantAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userid: "",
            usernm: "",
            password: "",
            preuserid: "",
        };

        this._handleOnClose = this._handleOnClose.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._handleRequest = this._handleRequest.bind(this);
    }

    _handleRequest(e) {
        e.preventDefault();
        const { userid, usernm, password } = this.state;
        if (!userid) {
            this.props.dispatch(openAlertDialog(i18n.t("msg_alert_userid"), false, "check", false, null, null, null, null));
            return;
        }
        if (!usernm) {
            this.props.dispatch(openAlertDialog(i18n.t("msg_alert_usernm"), false, "check", false, null, null, null, null));
            return;
        }
        if (!password) {
            this.props.dispatch(openAlertDialog(i18n.t("msg_alert_password"), false, "check", false, null, null, null, null));
            return;
        }
        const user = {
            tenantid: this.props.tenantid,
            userid: this.state.userid,
            usernm: this.state.usernm,
            password: this.state.password,
            preuserid: this.state.preuserid,
        };

        if (this.props.userInfo) {
            this.props.updateTenantAdmin(user, (error, result) => {
                if (result) {
                    this._handleClose();
                }
            });
        } else {
            this.props.setTenantAdmin(user, (error, result) => {
                if (result) {
                    this._handleClose();
                }
            });
        }
    }

    _handleClose() {
        this.props.onSave();
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
    }

    componentDidMount() {
        const { userInfo } = this.props;
        if (userInfo) {
            this.setState({
                tenantid: this.props.tenantid,
                userid: userInfo.userid,
                usernm: userInfo.usernm,
                preuserid: userInfo.userid,
                password: "",
            });
        }
    }

    _handleOnClose() {
        this.props.onClose();
    }

    render() {
        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={() => {
                        this._handleOnClose();
                    }}
                    dialogClassName="modal-dialog modal-dialog-centered sz_xlg"
                    backdrop="static"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{i18n.t("label_user")}</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    this._handleOnClose();
                                }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <form className="form-horizontal">
                                <div className="form-group">
                                    <label className="required" htmlFor="userid">
                                        {i18n.t("label_adminid")}
                                    </label>
                                    <input className="form-control " type="text" value={this.state.userid} onChange={this._handleChange} name="userid" />
                                </div>
                                <div className="form-group">
                                    <label className="required" htmlFor="usernm">
                                        {i18n.t("label_adminnm")}
                                    </label>
                                    <input className="form-control " type="text" value={this.state.usernm} onChange={this._handleChange} name="usernm" />
                                </div>
                                <div className="form-group">
                                    <label className="required" htmlFor="password">
                                        {i18n.t("label_adminpw")}
                                    </label>
                                    <input className="form-control " type="text" value={this.state.password} onChange={this._handleChange} name="password" />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={(e) => {
                                    this._handleRequest(e);
                                }}
                            >
                                {i18n.t("label_btn_save")}
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => {
                                    this._handleOnClose();
                                }}
                            >
                                {i18n.t("label_btn_cancel")}
                            </button>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            updateTenantAdmin,
            setTenantAdmin,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(TenantAdmin);
export { connectedPage as TenantAdmin };
