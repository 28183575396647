import { requestHeaders, requestBody, handleResponse } from "_helpers";
import { getCommonCodeReq, setCommonCodeReq } from "_actions";

export function getCode(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
            }),
        };
        return fetch(`/api/common/code/${params.codeid}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getCommonCodeReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getCommonCode(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
            }),
        };
        return fetch(`/api/common/${params.codeid}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getCommonCodeReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function setFeverRange(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const requestOptions = {
            method: "POST",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
            }),
            body: requestBody(params.codes),
        };
        return fetch(`/api/common/feverrange`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setCommonCodeReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function setCommonCode(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const requestOptions = {
            method: "PUT",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
            body: requestBody(params),
        };
        return fetch(`/api/common`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setCommonCodeReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}
