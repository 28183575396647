import { requestHeaders, requestBody, handleResponse, commonUtils } from "_helpers";
import {
    gerUsersReq,
    setUserReq,
    setUserDeviceConnectReq,
    // getUsersMonitorReq,
    getUserMonitorReq,
    // getUsersGraphReq,
    userLoginReq,
    // userLogoutReq,
} from "../_actions";
import moment from "moment";

// 로그인
export function login(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const body = {
            tenantid: params.tenantid,
            userid: params.userid,
            password: params.password,
        };
        const requestOptions = {
            method: "POST",
            headers: requestHeaders({ "Content-Type": "application/json" }),
            body: requestBody(body),
        };
        return fetch(`/api/users/login`, requestOptions)
            .then((response) => handleResponse(response, true))
            .then((response) => {
                const loginSession = {
                    tenantid: response.result.tenantid,
                    userid: response.result.userid,
                    grpid: response.result.grpid,
                    model: response.result.model,
                    email: response.result.email,
                    defaultgrpid: response.result.defaultgrpid,
                    defaultgrpnm: response.result.defaultgrpnm,
                    adminyn: response.result.adminyn,
                    tdisptype: response.result.tdisptype,
                };
                setLoginSession(loginSession);
                callback(null, response.result);
                dispatch(userLoginReq(loginSession));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function setUserDeviceConnect(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const requestOptions = {
            method: "POST",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
            }),
            //body: requestBody(body),
        };
        return fetch(`/api/users/device?connect=${params.connect}&userid=${params.userid}&deviceid=${params.deviceid}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setUserDeviceConnectReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function setUser(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        //const session = JSON.parse(localStorage.getItem("session"));
        const body = params;
        const requestOptions = {
            method: "POST",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
            body: requestBody(body),
        };
        return fetch(`/api/users`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setUserReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function updateUser(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        //const session = JSON.parse(localStorage.getItem("session"));
        const body = params;
        const requestOptions = {
            method: "PUT",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
            body: requestBody(body),
        };
        return fetch(`/api/users`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setUserReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function deleteUser(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const body = params;
        const requestOptions = {
            method: "DELETE",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
            }),
            body: requestBody(body),
        };
        return fetch(`/api/users?userid=${params.userid}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setUserReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function deleteTenantUser(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const body = params;
        const requestOptions = {
            method: "DELETE",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": params.tenantid,
            }),
            body: requestBody(body),
        };
        return fetch(`/api/users?userid=${params.userid}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setUserReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getUsers(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const timezone = localStorage.getItem("time-zone");
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
                "X-CLIENT-TIMEZONE": timezone,
            }),
        };
        return fetch(`/api/users?active=${params.active}&searchStr=${params.searchStr}&sort=${params.sort}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(gerUsersReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getTenants(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
            }),
        };
        return fetch(`/api/tenants`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(gerUsersReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getTenantAdmins(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
        };
        return fetch(`/api/tenants/${params.tenantid}/admins`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(gerUsersReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function setTenant(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        //const session = JSON.parse(localStorage.getItem("session"));
        const body = params;
        const requestOptions = {
            method: "POST",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
            body: requestBody(body),
        };
        return fetch(`/api/tenants`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setUserReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function updateTenantAdmin(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        //const session = JSON.parse(localStorage.getItem("session"));
        const body = params;
        const requestOptions = {
            method: "PUT",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
            body: requestBody(body),
        };
        return fetch(`/api/tenants/${params.tenantid}/admins`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setUserReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function setTenantAdmin(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        //const session = JSON.parse(localStorage.getItem("session"));
        const body = params;
        const requestOptions = {
            method: "POST",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
            body: requestBody(body),
        };
        return fetch(`/api/tenants/${params.tenantid}/admins`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setUserReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getUserStatsDetail(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
            }),
        };
        return fetch(`/api/users/${params.userid}/detail`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getUserMonitorReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getUserRecentDevice(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
            }),
        };
        return fetch(`/api/users/${params.userid}/devices`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getUserMonitorReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function logout() {
    return (dispatch) => {
        localStorage.clear();
    };
}

export function checkLogin() {
    const now = moment();
    const lastSessionTimeStr = localStorage.getItem("last-session-time");
    if (lastSessionTimeStr) {
        const lastSessionTimeDate = new Date(lastSessionTimeStr);
        const lastSessionTime = moment(lastSessionTimeDate.toISOString());
        const duration = Number(moment.duration(now - lastSessionTime).asMinutes());
        const sessionTimeout = commonUtils.isValidNumber(`${process.env.REACT_APP_SESSION_EXPIRED_TIME}`)
            ? Number(`${process.env.REACT_APP_SESSION_EXPIRED_TIME}`)
            : 0;
        if (sessionTimeout === 0 || duration < sessionTimeout) {
            localStorage.setItem("last-session-time", moment().toDate());
            return true;
        }
    }
    //logout();
    return false;
}

function setLoginSession(loginSession) {
    localStorage.setItem("session", JSON.stringify(loginSession));
    localStorage.setItem("last-session-time", moment().toDate());
    localStorage.setItem("time-zone", moment.tz.guess());
    localStorage.setItem("offset", moment.tz(moment.tz.guess()).format("Z"));
}

export function getUserPeriodTemperatures(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const timezone = localStorage.getItem("time-zone");
        const from = commonUtils.utcDate(params.from, "start");
        const to = commonUtils.utcDate(params.to, "end");
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
                "X-CLIENT-TIMEZONE": timezone,
            }),
        };
        return fetch(
            `/api/stats/users/${params.userid}/period?interval=${params.interval}&model=${session.model}&deviceid=${params.deviceid}&from=${from}&to=${to}`,
            requestOptions
        )
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                //dispatch(getUsersMonitorReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}
