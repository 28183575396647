/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getGroups, setUserGroupAdd, setUserGroupUp, setUserGroupDelete, getGroupUsers, deleteUser } from "_services";
import { UserGroup } from "./UserGroup";
import { openAlertDialog } from "../_actions/alert.actions";
import { Constants } from "../_constants/constants";
import { User } from "./User";
import Aux from "../hoc/_Aux";
import i18n from "../i18n";
import sortBy from "lodash/sortBy";
class UserGroupManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isUser: false,
            isUserGroup: false,
            selectedGrpid: "",
            selectedUserGrpid: "",
            selectedUserGrpnm: "",
            groups: [],
            users: [],
            userInfo: "",
        };
        this._handleUser = this._handleUser.bind(this);
        this._handleGroupDelete = this._handleGroupDelete.bind(this);
        this._handleUserGroup = this._handleUserGroup.bind(this);
        this._handleSaveClose = this._handleSaveClose.bind(this);
        this._handleUserSaveClose = this._handleUserSaveClose.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._getGroupUsers = this._getGroupUsers.bind(this);
        this._handleChangeUser = this._handleChangeUser.bind(this);
    }
    _handleChangeUser(data) {
        this.setState({
            ...this.state,
            isUser: !this.state.isUser,
            userInfo: data,
        });
    }
    _handleUser() {
        this.setState({
            ...this.state,
            isUser: !this.state.isUser,
            userInfo: "",
        });
    }
    _handleUserSaveClose() {
        const { selectedUserGrpid, selectedUserGrpnm } = this.state;
        this._getGroupUsers(selectedUserGrpid, selectedUserGrpnm);
        this._handleUser();
    }

    componentDidMount() {
        this._getGroups();
    }

    _handleSaveClose() {
        this._getGroups();
        this._handleUserGroup();
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            ...this.state,
            [name]: value,
        });
    }

    _handleUserGroup(e, grpid) {
        this.setState({
            ...this.state,
            selectedGrpid: grpid ? grpid : "",
            isUserGroup: !this.state.isUserGroup,
        });
    }

    _getGroupUsers(grpid, grpnm) {
        this.props.getGroupUsers({ grpid: grpid }, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    selectedUserGrpid: grpid,
                    selectedUserGrpnm: grpnm,
                    users: sortBy(result, "userid"),
                });
            }
        });
    }

    _handleGroupDelete(e, grpid, usercnt, grpcnt) {
        if (usercnt > 0) {
            this.props.dispatch(openAlertDialog(Constants.Message.alert.usercnt_exists, false, "check", false, null, null, null, null));
            return;
        }
        if (grpcnt > 0) {
            this.props.dispatch(openAlertDialog(Constants.Message.alert.grpcnt_exists, false, "check", false, null, null, null, null));
            return;
        }

        this.props.setUserGroupDelete({ grpid: grpid }, (error, result) => {
            if (result) {
                this._getGroups();
            }
        });
    }
    _handleDeleteUser(e, userid) {
        e.preventDefault();
        this.props.deleteUser({ userid: userid }, (error, result) => {
            if (result) {
                const { selectedUserGrpid, selectedUserGrpnm } = this.state;
                this._getGroupUsers(selectedUserGrpid, selectedUserGrpnm);
            }
        });
    }

    _getGroups() {
        this.props.getGroups({}, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    groups: result,
                });
            }
        });
    }
    render() {
        return (
            <Aux>
                <div className="main_content">
                    <div className="page_content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page_title_box">
                                        <h4>{i18n.t("menu_usergroup_mng")}</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xl-4 col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">{i18n.t("label_group")}</h4>

                                            <div className="group_list">
                                                <ul className="list">
                                                    {this.state.groups &&
                                                        this.state.groups.map((data) => (
                                                            <li className={"depth" + data.lev} key={data.grpid}>
                                                                <span className="group_name" onClick={() => this._getGroupUsers(data.grpid, data.grpnm)}>
                                                                    {data.grpnm}
                                                                </span>
                                                                <div className="btns">
                                                                    <button
                                                                        type="button"
                                                                        className="ico_btn"
                                                                        title="Add"
                                                                        onClick={(e) => this._handleUserGroup(e, data.grpid)}
                                                                    >
                                                                        <i className="bx bx-plus"></i>
                                                                    </button>
                                                                </div>
                                                                <div className="btns">
                                                                    <button
                                                                        type="button"
                                                                        className="ico_btn"
                                                                        title="Add"
                                                                        onClick={(e) => this._handleUserGroup(e, data.grpid)}
                                                                    >
                                                                        <i className="bx bx-plus"></i>
                                                                    </button>
                                                                    {parseInt(data.lev) !== 1 && (
                                                                        <button
                                                                            type="button"
                                                                            className="ico_btn delete"
                                                                            title="Delete"
                                                                            onClick={(e) => this._handleGroupDelete(e, data.grpid, data.usercnt, data.grpcnt)}
                                                                        >
                                                                            <i className="bx bxs-trash"></i>
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-8 col-md-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">{this.state.selectedUserGrpnm}</h4>

                                            <div className="title_btn">
                                                {this.state.selectedUserGrpid && (
                                                    <button className="btn btn-primary" type="button" onClick={this._handleUser}>
                                                        <i className="bx bx-plus"></i> {i18n.t("label_btn_adduser")}
                                                    </button>
                                                )}
                                            </div>

                                            <div className="table-responsive">
                                                <table className="table table-centered table-nowrap">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th>{i18n.t("label_group")}</th>
                                                            <th>{i18n.t("label_userid")}</th>
                                                            <th>{i18n.t("label_usernm")}</th>
                                                            <th style={{ width: "75px" }}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.users &&
                                                            this.state.users.map((data) => (
                                                                <tr key={data.userid}>
                                                                    <td>{data.grpnm}</td>
                                                                    <td style={{ cursor: "pointer" }} onClick={() => this._handleChangeUser(data)}>
                                                                        {data.userid}
                                                                    </td>
                                                                    <td>{data.usernm}</td>
                                                                    <td>
                                                                        <button
                                                                            type="button"
                                                                            className="ico_btn edit"
                                                                            title="Edit"
                                                                            onClick={() => this._handleChangeUser(data)}
                                                                        >
                                                                            <i className="bx bx-edit-alt"></i>
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="ico_btn delete"
                                                                            title="Delete"
                                                                            onClick={(e) => this._handleDeleteUser(e, data.userid)}
                                                                        >
                                                                            <i className="bx bxs-trash"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isUser && (
                    <User
                        open={this.state.isUser}
                        onClose={this._handleUser}
                        onSave={this._handleUserSaveClose}
                        grpid={this.state.selectedUserGrpid}
                        userInfo={this.state.userInfo}
                    />
                )}
                {this.state.isUserGroup && (
                    <UserGroup open={this.state.isUserGroup} onClose={this._handleUserGroup} onSave={this._handleSaveClose} grpid={this.state.selectedGrpid} />
                )}
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setUserGroupAdd,
            setUserGroupUp,
            setUserGroupDelete,
            getGroups,
            getGroupUsers,
            deleteUser,
            dispatch,
        },
        dispatch
    );

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(UserGroupManagement);
export { connectedPage as UserGroupManagement };
// export default connect(mapStateToProps, mapDispatchToProps)(windowSize(UserGroupManagement));
