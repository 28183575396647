/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserDeltaStats, getGroupStatsCount, getAllGroupUsersStats, getUserTemperatures, getUserPeriodTemperatures } from "_services";
// import { FeverLineChart } from "./FeverLineChart";
import { UserTemperatureLog } from "./UserTemperatureLog";
// import { UserPopMap } from "./UserPopMap";
import { UserDetail } from "./UserDetail";
import { UserDetailDaily } from "./UserDetailDaily";
import { CompareChartDelta } from "./CompareChartDelta";
import { ExcelDownload } from "./ExcelDownload";
import { commonUtils } from "_helpers";
import { DashboardChart } from "_pages";
import Aux from "../hoc/_Aux";
import moment from "moment";
import i18n from "../i18n";

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isTemperatureLog: false,
            isLineChart: false,
            isUserDetail: false,
            isExcelDownload: false,
            defaultLat: "",
            defaultLng: "",
            stats: "",
            users: [],
            allusers: [],
            selectedUser: "",
            temperatures: [],
            deltavalue: [],
            logselectedUser: "",
            logtemperatures: [],
            mapselectedUserNm: "",
            geolocations: [],
            dateInterval: "daily",
            baseDay: moment(),
            startDay: moment().format("YYYY-MM-DD"),
            endDay: moment().format("YYYY-MM-DD"),
            userDetailSelected: "",
            display: "list",
        };
        this._getGroupStatsCount = this._getGroupStatsCount.bind(this);
        this._handleUserDetail = this._handleUserDetail.bind(this);
        this._handleLog = this._handleLog.bind(this);
        this._handleLogClose = this._handleLogClose.bind(this);
        this._handleUserClose = this._handleUserClose.bind(this);

        this._handleDateInterval = this._handleDateInterval.bind(this);
        this._handleExcelDownload = this._handleExcelDownload.bind(this);

        this._handleTemperatureUsers = this._handleTemperatureUsers.bind(this);
        this._handleAllUsers = this._handleAllUsers.bind(this);

        this._handleDisplay = this._handleDisplay.bind(this);
    }
    _handleDisplay(display) {
        this.setState({
            ...this.state,
            display: display,
        });
    }
    _handleAllUsers() {
        this.setState({
            ...this.state,
            users: this.state.allusers,
        });
    }

    _handleTemperatureUsers(flag) {
        const newUsers = this.state.allusers.filter((data) => data.tlabel === flag);
        this.setState({
            ...this.state,
            users: newUsers,
        });
    }

    _handleDateInterval(flag) {
        this.setState(
            {
                ...this.state,
                baseDay: moment(),
                dateInterval: flag,
                startDay: commonUtils.statsDateRange(moment(), flag, "now", "start"),
                endDay: commonUtils.statsDateRange(moment(), flag, "now", "end"),
                //isLineChart: false,
            },
            () => {
                this._getGroupStatsCount();
            }
        );
    }

    _handleLogClose() {
        this.setState({
            ...this.state,
            isTemperatureLog: !this.state.isTemperatureLog,
        });
    }

    _handleChartClose() {
        this.setState({
            ...this.state,
            isLineChart: !this.state.isLineChart,
        });
    }
    _handleUserClose() {
        this.setState({
            ...this.state,
            isUserDetail: !this.state.isUserDetail,
        });
    }

    _handleUserDetail(e, userid) {
        e.preventDefault();
        this.setState({
            ...this.state,
            isUserDetail: !this.state.isUserDetail,
            userDetailSelected: userid,
        });
    }

    _handleExcelDownload(userid, usernm) {
        this.props.getUserTemperatures(
            {
                from: this.state.startDay,
                to: this.state.endDay,
                userid: userid,
            },

            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        isExcelDownload: !this.state.isExcelDownload,
                        logtemperatures: result,
                        logselectedUser: usernm,
                    });
                }
            }
        );
    }

    _handleLog(userid, usernm) {
        this.props.getUserTemperatures(
            {
                from: this.state.startDay,
                to: this.state.endDay,
                userid: userid,
            },

            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        isTemperatureLog: !this.state.isTemperatureLog,
                        logselectedUser: usernm,
                        logtemperatures: result,
                    });
                }
            }
        );
    }

    componentDidMount() {
        this._getGroupStatsCount();
    }

    _getGroupStatsCount() {
        this.props.getGroupStatsCount(
            {
                from: this.state.startDay,
                to: this.state.endDay,
                grpid: "all",
                interval: this.state.dateInterval,
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        stats: result,
                    });
                    this._getTargetUsers();
                }
            }
        );
    }

    _getTargetUsers() {
        this.props.getAllGroupUsersStats(
            {
                from: this.state.startDay,
                to: this.state.endDay,
                grpid: "all",
                interval: this.state.dateInterval,
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        users: result,
                        allusers: result,
                    });
                } else {
                    this.setState({
                        ...this.state,
                        users: [],
                    });
                }
            }
        );
    }

    render() {
        const { stats } = this.state;
        const session = JSON.parse(localStorage.getItem("session"));
        return (
            <Aux>
                <div className="main_content">
                    <div className="page_content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page_btn_box">
                                        <div className="date_text">
                                            <span onClick={this._handleAllUsers} style={{ cursor: "pointer" }}>
                                                {i18n.t("menu_dashboard")}[
                                                {this.state.dateInterval === "daily" ? this.state.startDay : this.state.startDay + "~" + this.state.endDay}]
                                            </span>

                                            {/* <div style={{ paddingLeft: "20px" }}>
                                                <i className="fa fa-th" onClick={() => this._handleDisplay("list")} style={{ cursor: "pointer" }}></i>
                                                &nbsp;&nbsp;
                                                <i className="fa fa-line-chart" onClick={() => this._handleDisplay("chart")} style={{ cursor: "pointer" }}></i>
                                            </div> */}
                                        </div>
                                        <div className="page_total_box">
                                            <span className="text">
                                                Total : <em className="count">{stats.allcnt}</em>
                                            </span>
                                            <span className="text">
                                                Measured : <em className="count">{stats.concnt}</em>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-xl-3">
                                    <div className="card user_temp normality">
                                        <div className="card-body" style={{ cursor: "pointer" }} onClick={() => this._handleTemperatureUsers("label_normal")}>
                                            <div className="media">
                                                <div className="media-body">
                                                    <p className="title">
                                                        {i18n.t("label_normal")}
                                                        <button type="button" className="btn_info"></button>
                                                        <span className="temp">
                                                            {session.tdisptype === "C" ? stats.normalfrom : stats.fnormalfrom} ~
                                                            {session.tdisptype === "C" ? stats.normalto : stats.fnormalto}
                                                        </span>
                                                    </p>
                                                    <h4 className="mb-0">
                                                        <span className="counter big">{stats.normalcnt}</span>
                                                        <span className="counter small">{stats.concnt}</span>
                                                    </h4>
                                                </div>
                                                <div className="avatar">
                                                    <span className="avatar_title">
                                                        <i className="ico_temp"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="progress_area">
                                                <p className="percent counter">
                                                    {stats.normalcnt > 0 ? ((stats.normalcnt / stats.concnt) * 100).toFixed(1) : 0}
                                                </p>
                                                <div className="progress">
                                                    <div
                                                        className="progress-bar active"
                                                        role="progressbar"
                                                        style={{ width: (stats.normalcnt / stats.concnt) * 100 + "%" }}
                                                        aria-valuenow={(stats.normalcnt / stats.concnt) * 100}
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-xl-3">
                                    <div className="card user_temp high">
                                        <div
                                            className="card-body"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => this._handleTemperatureUsers("label_high_fever")}
                                        >
                                            <div className="media">
                                                <div className="media-body">
                                                    <p className="title">
                                                        {i18n.t("label_high_fever")}
                                                        <button type="button" className="btn_info"></button>
                                                        <span className="temp">{session.tdisptype === "C" ? stats.highfrom : stats.fhighfrom} ~ </span>
                                                    </p>
                                                    <h4 className="mb-0">
                                                        <span className="counter big">{stats.highcnt}</span>
                                                        <span className="counter small">{stats.concnt}</span>
                                                    </h4>
                                                </div>
                                                <div className="avatar">
                                                    <span className="avatar_title">
                                                        <i className="ico_temp"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="progress_area">
                                                <p className="percent counter">{stats.highcnt > 0 ? ((stats.highcnt / stats.concnt) * 100).toFixed(1) : 0}</p>
                                                <div className="progress">
                                                    <div
                                                        className="progress-bar active"
                                                        role="progressbar"
                                                        style={{ width: (stats.highcnt / stats.concnt) * 100 + "%" }}
                                                        aria-valuenow={(stats.highcnt / stats.concnt) * 100}
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-xl-3">
                                    <div className="card user_temp middle_high">
                                        <div
                                            className="card-body"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => this._handleTemperatureUsers("label_midium_fever")}
                                        >
                                            <div className="media">
                                                <div className="media-body">
                                                    <p className="title">
                                                        {i18n.t("label_midium_fever")}
                                                        <button type="button" className="btn_info"></button>
                                                        <span className="temp">
                                                            {session.tdisptype === "C" ? stats.moderatefrom : stats.fmoderatefrom} ~
                                                            {session.tdisptype === "C" ? stats.moderateto : stats.fmoderateto}
                                                        </span>
                                                    </p>
                                                    <h4 className="mb-0">
                                                        <span className="counter big">{stats.middlecnt}</span>
                                                        <span className="counter small">{stats.concnt}</span>
                                                    </h4>
                                                </div>
                                                <div className="avatar">
                                                    <span className="avatar_title">
                                                        <i className="ico_temp"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="progress_area">
                                                <p className="percent counter">
                                                    {stats.middlecnt > 0 ? ((stats.middlecnt / stats.concnt) * 100).toFixed(1) : 0}
                                                </p>
                                                <div className="progress">
                                                    <div
                                                        className="progress-bar active"
                                                        role="progressbar"
                                                        style={{ width: (stats.middlecnt / stats.concnt) * 100 + "%" }}
                                                        aria-valuenow={(stats.middlecnt / stats.concnt) * 100}
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-xl-3">
                                    <div className="card user_temp low_high">
                                        <div
                                            className="card-body"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => this._handleTemperatureUsers("label_mild_fever")}
                                        >
                                            <div className="media">
                                                <div className="media-body">
                                                    <p className="title">
                                                        {i18n.t("label_mild_fever")}
                                                        <button type="button" className="btn_info"></button>
                                                        <span className="temp">
                                                            {session.tdisptype === "C" ? stats.lowfrom : stats.flowfrom} ~
                                                            {session.tdisptype === "C" ? stats.lowto : stats.flowto}
                                                        </span>
                                                    </p>
                                                    <h4 className="mb-0">
                                                        <span className="counter big">{stats.lowcnt}</span>
                                                        <span className="counter small">{stats.concnt}</span>
                                                    </h4>
                                                </div>
                                                <div className="avatar">
                                                    <span className="avatar_title">
                                                        <i className="ico_temp"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="progress_area">
                                                <p className="percent counter">{stats.lowcnt > 0 ? ((stats.lowcnt / stats.concnt) * 100).toFixed(1) : 0}</p>
                                                <div className="progress">
                                                    <div
                                                        className="progress-bar active"
                                                        role="progressbar"
                                                        style={{ width: (stats.lowcnt / stats.concnt) * 100 + "%" }}
                                                        aria-valuenow={(stats.lowcnt / stats.concnt) * 100}
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.state.display === "list" && (
                                <div className="row">
                                    {this.state.users &&
                                        this.state.users.map((data, index) => (
                                            <div className="col-md-6 col-xl-3 card_wrap" key={index}>
                                                <a href="#" className="item-link" onClick={(e) => this._handleUserDetail(e, data.userid)}>
                                                    <div className={"card user_temp " + data.tlabel}>
                                                        <div className="card-body">
                                                            <div className={"personal_info " + data.tlabel}>
                                                                <div className="text_area">
                                                                    <p className="name">{data.usernm}</p>
                                                                    <p className="dept">{data.grpnm}</p>
                                                                    <div className="device">
                                                                        <p className="update">
                                                                            <span>{commonUtils.localTimezoneTime2(data.lastupdated)}</span> updated
                                                                        </p>
                                                                        <p className="battery">
                                                                            Battery <span>{data.battery}%</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="ico_area">
                                                                    <div className="avatar">
                                                                        <span className="avatar_title">
                                                                            <i className="ico_temp"></i>
                                                                        </span>
                                                                    </div>
                                                                    <p className="status">{i18n.t(data.tlabel)}</p>
                                                                </div>
                                                            </div>
                                                            <div className="care_info">
                                                                <div className="temp" style={{ width: "80%" }}>
                                                                    <div className="item">
                                                                        <p className="tit">
                                                                            {/* <b>Max Temp {" (°" + session.tdisptype + ")"}</b> */}
                                                                            <b>Max Temp {" (" + commonUtils.localTimezoneTime(data.maxsensingtime) + ")"}</b>
                                                                        </p>
                                                                        <p className="data">
                                                                            <span className="num">{session.tdisptype === "C" ? data.tmax : data.tfmax}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>

                                                                <div className="etc">
                                                                    <div className="etc_top">
                                                                        <div className="item">
                                                                            <p className="tit">Temp {" (°" + session.tdisptype + ")"}</p>
                                                                            <p className="data">
                                                                                <span className="num">
                                                                                    {session.tdisptype === "C" ? data.tlast : data.tflast} (
                                                                                    {session.tdisptype === "C"
                                                                                        ? (data.tmax - data.tlast).toFixed(1)
                                                                                        : (data.tfmax - data.tflast).toFixed(1)}
                                                                                    )
                                                                                </span>
                                                                            </p>
                                                                        </div>

                                                                        <div className="item">
                                                                            <p className="tit">SPO2</p>
                                                                            <p className="data">
                                                                                <span className="num">{data.oxygenlast}</span> %
                                                                            </p>
                                                                        </div>
                                                                        <div className="item">
                                                                            <p className="tit">Heart rate</p>
                                                                            <p className="data">
                                                                                <span className="num">{data.heartlast}</span> bpm
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="etc_bottom">
                                                                        <div className="item">
                                                                            <p className="tit">
                                                                                Blood <span className="block">Pressure</span>
                                                                            </p>
                                                                            <p className="data">
                                                                                <span className="num">
                                                                                    {data.sbloodlast}/{data.dbloodlast}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                        <div className="item">
                                                                            <p className="tit">
                                                                                Respir<span className="abbr">atory</span>
                                                                            </p>
                                                                            <p className="data">
                                                                                <span className="num">{data.breathlast}</span> bpm
                                                                            </p>
                                                                        </div>
                                                                        <div className="item">
                                                                            <p className="tit">Step</p>
                                                                            <p className="data">
                                                                                <span className="num">{data.steplast}</span>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        ))}
                                </div>
                            )}
                            {this.state.display === "chart" && <DashboardChart temperatures={this.state.allusers} />}
                        </div>
                    </div>
                </div>

                {this.state.isExcelDownload && (
                    <ExcelDownload
                        temperatures={this.state.logtemperatures}
                        usernm={this.state.logselectedUser}
                        startDay={this.state.startDay}
                        endDay={this.state.endDay}
                    />
                )}

                {/* {this.state.isUserDetail && (
                    <UserDetail
                        userid={this.state.userDetailSelected}
                        startDay={this.state.startDay}
                        endDay={this.state.endDay}
                        interval={this.state.dateInterval}
                        open={this.state.isUserDetail}
                        onClose={this._handleUserClose}
                    />
                )} */}

                {this.state.isUserDetail && (
                    <UserDetailDaily
                        userid={this.state.userDetailSelected}
                        startDay={this.state.startDay}
                        endDay={this.state.endDay}
                        interval={this.state.dateInterval}
                        open={this.state.isUserDetail}
                        onClose={this._handleUserClose}
                    />
                )}

                {this.state.isLineChart && (
                    <CompareChartDelta
                        onClose={this._handleChartClose}
                        interval={this.state.dateInterval}
                        temperatures={this.state.temperatures}
                        deltavalue={this.state.deltavalue}
                        selectedUser={this.state.selectedUser}
                        startDay={this.state.startDay}
                        endDay={this.state.endDay}
                        open={this.state.isLineChart}
                    />
                )}

                {this.state.isTemperatureLog && (
                    <UserTemperatureLog
                        temperatures={this.state.logtemperatures}
                        selectedUser={this.state.logselectedUser}
                        open={this.state.isTemperatureLog}
                        onClose={this._handleLogClose}
                    />
                )}
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getUserDeltaStats,
            getUserPeriodTemperatures,
            getAllGroupUsersStats,
            getUserTemperatures,
            getGroupStatsCount,
            dispatch,
        },
        dispatch
    );

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(Dashboard);
export { connectedPage as Dashboard };
