/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setUser, updateUser } from "../_services";
import { openAlertDialog } from "../_actions";
import { Modal } from "react-bootstrap";
import i18n from "../i18n";
class User extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userid: "",
            usernm: "",
            password: "",
            email: "",
            phone: "",
            nickname: "",
            age: "",
            sex: "male",
            height: "",
            weight: "",
            skin: "fefefe",
            bplevel: "",
            sbp: "",
            dbp: "",
            bptype: "On the low Side",
            temperature: "36.5",
            deviceid: "",
        };

        this._handleOnClose = this._handleOnClose.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._handleRequest = this._handleRequest.bind(this);
    }

    _handleRequest(e) {
        e.preventDefault();
        const { userid, usernm } = this.state;
        if (!userid) {
            this.props.dispatch(openAlertDialog(i18n.t("msg_alert_userid"), false, "check", false, null, null, null, null));
            return;
        }
        if (!usernm) {
            this.props.dispatch(openAlertDialog(i18n.t("msg_alert_usernm"), false, "check", false, null, null, null, null));
            return;
        }
        const session = JSON.parse(localStorage.getItem("session"));
        const user = {
            tenantid: session.tenantid,
            grpid: this.props.grpid,
            userid: this.state.userid,
            usernm: this.state.usernm,
            password: this.state.password,
            email: this.state.email,
            phone: this.state.phone,
            nickname: this.state.nickname,
            age: this.state.age,
            sex: this.state.sex,
            height: this.state.height,
            weight: this.state.weight,
            skin: this.state.skin,
            bplevel: this.state.bplevel,
            sbp: this.state.sbp,
            dbp: this.state.dbp,
            bptype: this.state.bptype,
            temperature: this.state.temperature ? this.state.temperature : "36.5",
            deviceid: this.state.deviceid,
        };

        if (this.props.userInfo) {
            this.props.updateUser(user, (error, result) => {
                if (result) {
                    this._handleClose();
                }
            });
        } else {
            this.props.setUser(user, (error, result) => {
                if (result) {
                    this._handleClose();
                }
            });
        }
    }

    _handleClose() {
        this.props.onSave();
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
    }

    componentDidMount() {
        const { userInfo } = this.props;
        const session = JSON.parse(localStorage.getItem("session"));
        if (userInfo) {
            this.setState({
                tenantid: session.tenantid,
                grpid: userInfo.grpid ? userInfo.grpid : "",
                userid: userInfo.userid ? userInfo.userid : "",
                usernm: userInfo.usernm ? userInfo.usernm : "",
                password: userInfo.password ? userInfo.password : "",
                email: userInfo.email ? userInfo.email : "",
                phone: userInfo.phone ? userInfo.phone : "",
                nickname: userInfo.nickname ? userInfo.nickname : "",
                age: userInfo.age ? userInfo.age : "",
                sex: userInfo.sex ? userInfo.sex : "",
                height: userInfo.height ? userInfo.height : "",
                weight: userInfo.weight ? userInfo.weight : "",
                skin: userInfo.skin ? userInfo.skin : "",
                bplevel: userInfo.bplevel ? userInfo.bplevel : "",
                sbp: userInfo.sbp ? userInfo.sbp : "",
                dbp: userInfo.dbp ? userInfo.dbp : "",
                bptype: userInfo.bptype ? userInfo.bptype : "",
                temperature: userInfo.temperature ? userInfo.temperature : "36.5",
                deviceid: userInfo.deviceid ? userInfo.deviceid : "",
            });
        }
    }

    _handleOnClose() {
        this.props.onClose();
    }

    render() {
        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={() => {
                        this._handleOnClose();
                    }}
                    dialogClassName="modal-dialog modal-dialog-centered sz_xlg"
                    backdrop="static"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{i18n.t("label_user")}</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    this._handleOnClose();
                                }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <form className="form-horizontal">
                                <div className="form-group row">
                                    <label className="required col-sm-3 col-form-label" htmlFor="userid">
                                        {i18n.t("label_userid")}
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            className={"form-control " + (this.props.userInfo ? "input-bg" : "")}
                                            type="text"
                                            value={this.state.userid}
                                            onChange={this._handleChange}
                                            name="userid"
                                            disabled={this.props.userInfo ? true : false}
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="required col-sm-3 col-form-label" htmlFor="usernm">
                                        {i18n.t("label_usernm")}
                                    </label>
                                    <div className="col-sm-9">
                                        <input className="form-control " type="text" value={this.state.usernm} onChange={this._handleChange} name="usernm" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="password">
                                        {i18n.t("label_password")}
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            className="form-control "
                                            type="text"
                                            value={this.state.password}
                                            onChange={this._handleChange}
                                            name="password"
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="email">
                                        {i18n.t("label_email")}
                                    </label>
                                    <div className="col-sm-9">
                                        <input className="form-control " type="text" value={this.state.email} onChange={this._handleChange} name="email" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="phone">
                                        {i18n.t("label_phone")}
                                    </label>
                                    <div className="col-sm-9">
                                        <input className="form-control " type="text" value={this.state.phone} onChange={this._handleChange} name="phone" />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="nickname">
                                        {i18n.t("label_nickname")}
                                    </label>
                                    <div className="col-sm-9">
                                        <input className="form-control" type="text" value={this.state.nickname} onChange={this._handleChange} name="nickname" />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="age">
                                        {i18n.t("label_age")}
                                    </label>
                                    <div className="col-sm-9">
                                        <input className="form-control " type="text" value={this.state.age} onChange={this._handleChange} name="age" />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="sex">
                                        {i18n.t("label_sex")}
                                    </label>
                                    <div className="col-sm-9">
                                        <select onChange={this._handleChange} value={this.state.sex} className="form-control" name="sex">
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="height">
                                        {i18n.t("label_height")}
                                    </label>
                                    <div className="col-sm-9">
                                        <input className="form-control " type="text" value={this.state.height} onChange={this._handleChange} name="height" />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="weight">
                                        {i18n.t("label_weight")}
                                    </label>
                                    <div className="col-sm-9">
                                        <input className="form-control " type="text" value={this.state.weight} onChange={this._handleChange} name="weight" />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="skin">
                                        {i18n.t("label_skin")}
                                    </label>
                                    <div className="col-sm-9">
                                        <select onChange={this._handleChange} value={this.state.skin} className="form-control" name="skin">
                                            <option value="#">Choose Skin color </option>
                                            <option value="fefefe" style={{ background: "#fefefe", color: " #000" }}>
                                                fefefe
                                            </option>
                                            <option value="fbe7ce" style={{ background: "#fbe7ce", color: " #000" }}>
                                                fbe7ce
                                            </option>
                                            <option value="f1d99f" style={{ background: "#f1d99f", color: " #000" }}>
                                                f1d99f
                                            </option>
                                            <option value="c0915a" style={{ background: "#c0915a", color: " #fff" }}>
                                                c0915a
                                            </option>
                                            <option value="5d4528" style={{ background: "#5d4528", color: " #fff" }}>
                                                5d4528
                                            </option>
                                            <option value="3d2910" style={{ background: "#3d2910", color: " #fff" }}>
                                                3d2910
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="bptype">
                                        {i18n.t("label_bptype")}
                                    </label>
                                    <div className="col-sm-9">
                                        <select className="form-control" name="bptype" value={this.state.bptype} onChange={this._handleChange}>
                                            <option value="On the low Side">On the low Side</option>
                                            <option value="Normal">Normal</option>
                                            <option value="Slightly High">Slightly High</option>
                                            <option value="Moderately High">Moderately High</option>
                                            <option value="Severely High">Severely High</option>
                                        </select>
                                    </div>
                                </div>

                                {/* <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="bplevel">
                                        {i18n.t("label_bplevel")}
                                    </label>
                                    <div className="col-sm-9">
                                        <input className="form-control " type="text" value={this.state.bplevel} onChange={this._handleChange} name="bplevel" />
                                    </div>
                                </div> */}
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="sbp">
                                        {i18n.t("label_sbp")}
                                    </label>
                                    <div className="col-sm-9">
                                        <input className="form-control " type="text" value={this.state.sbp} onChange={this._handleChange} name="sbp" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="dbp">
                                        {i18n.t("label_dbp")}
                                    </label>
                                    <div className="col-sm-9">
                                        <input className="form-control " type="text" value={this.state.dbp} onChange={this._handleChange} name="dbp" />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="temperature">
                                        {i18n.t("label_base_temperature")}
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            className="form-control "
                                            type="text"
                                            value={this.state.temperature}
                                            onChange={this._handleChange}
                                            name="temperature"
                                        />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-3 col-form-label" htmlFor="deviceid">
                                        {i18n.t("label_deviceid")}
                                    </label>
                                    <div className="col-sm-9">
                                        <input
                                            className="form-control "
                                            type="text"
                                            value={this.state.deviceid}
                                            onChange={this._handleChange}
                                            name="deviceid"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={(e) => {
                                    this._handleRequest(e);
                                }}
                            >
                                {i18n.t("label_btn_save")}
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => {
                                    this._handleOnClose();
                                }}
                            >
                                {i18n.t("label_btn_cancel")}
                            </button>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setUser,
            updateUser,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(User);
export { connectedPage as User };
