export const Constants = {
    Message: {
        confirm: {
            save: "Save it?",
            device_disconnect: "연결을 해제 하시겠습니까?",
        },
        alert: {
            saved: "Saved !.",
            userid_empty: "Please enter user ID.",
            usernm_empty: "Please enter user Name.",
            deviceid_empty: "Please enter Device ID.",
            model_empty: "Please choose Device Model.",
            devicetype_empty: "Please choose Device Type.",
            gatewayid_empty: "Please enter AtoZ ID.",
            grpnm_empty: "Please enter Group Name.",
            usercnt_exists: "A user exists in the Group! Delete user first.",
            grpcnt_exists: "Subgroups exist. Delete subgroups first.",
        },
    },
};
