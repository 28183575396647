import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { configureFakeBackend, configureStore } from "_helpers";
import App from "App";
import "i18n";

// require("es6-promise").polyfill();
// require("isomorphic-fetch");

// fetch("//offline-news-api.herokuapp.com/stories")
//     .then(function(response) {
//         if (response.status >= 400) {
//             throw new Error("Bad response from server");
//         }
//         return response.json();
//     })
//     .then(function(stories) {
//         console.log(stories);
//     });

const store = configureStore();

configureFakeBackend();

const startApp = () => {
    render(
        <Provider store={store}>
            <CookiesProvider>
                <App />
            </CookiesProvider>
        </Provider>,
        document.getElementById("root")
    );
};

//localStorage.clear();
window.onpopstate = function () {
    window.location.href = "/";
};
startApp();
