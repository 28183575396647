import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { login, logout } from "../_services";
import { openAlertDialog } from "../_actions";
import i18n from "../i18n";
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.props.logout();

        const { cookies } = this.props;
        this.state = {
            userid: cookies.get("rememberMe") === "true" ? cookies.get("userid") : "",
            tenantid: cookies.get("rememberMe") === "true" ? cookies.get("tenantid") : "",
            password: "",
            rememberMe: cookies.get("rememberMe") === "true" ? true : false,
        };
        this._handleChange = this._handleChange.bind(this);
        this._handleCheck = this._handleCheck.bind(this);
        this._handleRememberMe = this._handleRememberMe.bind(this);
    }
    _handleCheck() {
        const { tenantid, userid, password } = this.state;
        if (!tenantid) {
            this.props.dispatch(openAlertDialog(i18n.t("msg_alert_tenantid"), false, "infomation", false, null, null, null, null));
        } else if (!userid) {
            this.props.dispatch(openAlertDialog(i18n.t("msg_alert_userid"), false, "infomation", false, null, null, null, null));
        } else if (!password) {
            this.props.dispatch(openAlertDialog(i18n.t("msg_alert_password"), false, "infomation", false, null, null, null, null));
        } else {
            this.props.login({ tenantid, userid, password }, (error, result) => {
                //console.log("result", result);
                if (result) {
                    this._setLoginCookies();
                    this.props.history.push("/dashboard");
                } else {
                    this.props.dispatch(openAlertDialog(i18n.t("msg_alert_loginfail"), false, "infomation", false, null, null, null, null));
                }
            });
        }
    }
    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            ...this.state,
            [name]: value,
        });
    }

    _handleRememberMe(e) {
        this.setState({
            ...this.state,
            rememberMe: e.target.checked,
        });
    }

    _setLoginCookies() {
        const { cookies } = this.props;
        if (this.state.rememberMe) {
            cookies.set("rememberMe", "true", { path: "/" });
            cookies.set("tenantid", this.state.tenantid, { path: "/" });
            cookies.set("userid", this.state.userid, { path: "/" });
        } else {
            cookies.remove("rememberMe");
            cookies.remove("userid");
            cookies.remove("tenantid");
        }
    }

    componentDidMount() {}

    render() {
        return (
            <>
                <div id="layout-wrapper" className="">
                    <div className="account-pages my-5 pt-sm-5">
                        <div className="container login">
                            <div className="row justify-content-center">
                                <div className="col-md-8 col-lg-6 col-xl-5">
                                    <div className="card overflow-hidden">
                                        <div className="bg-soft-primary">
                                            <div className="row">
                                                <div className="col-7">
                                                    <div className="text-primary p-4">
                                                        <h5 className="text-primary">Welcome Back !</h5>
                                                        <p>Sign in to continue.</p>
                                                    </div>
                                                </div>
                                                <div className="col-5 align-self-end">
                                                    <img src="../common/images/profile-img.png" alt="" className="img-fluid" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body pt-0">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img src="../common/images/logo_login.png" alt="" className="rounded-circle" height="34" />
                                                </span>
                                            </div>

                                            <div className="p-2">
                                                <form className="form-horizontal">
                                                    <div className="form-group">
                                                        <label htmlFor="tenantid">Tenant ID</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Tenant"
                                                            name="tenantid"
                                                            value={this.state.tenantid}
                                                            onChange={this._handleChange}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="userid">User ID</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="User ID"
                                                            name="userid"
                                                            value={this.state.userid}
                                                            onChange={this._handleChange}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="password">Password</label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            placeholder="password"
                                                            name="password"
                                                            value={this.state.password}
                                                            onChange={this._handleChange}
                                                        />
                                                    </div>
                                                    <div className="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            name="rememberMe"
                                                            id="rememberMe"
                                                            checked={this.state.rememberMe}
                                                            onChange={this._handleRememberMe}
                                                            calss="custom-control-input"
                                                        />
                                                        <label htmlFor="rememberMe" className="cr">
                                                            Remember me
                                                        </label>
                                                    </div>
                                                    <div className="mt-3">
                                                        <button className="btn btn-primary btn_login" type="button" onClick={this._handleCheck}>
                                                            Log In
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 text-center">
                                        <p>© 2020 AMOSENSE. All Rights Reserved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            logout,
            login,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(Login);
export { connectedPage as Login };
