/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getGroups, getGroupUsersStats, getGroupStatsCount, getGroupUsersPeriodTemperatures, getUserTemperatures } from "_services";
// import { UsersChart } from "./UsersChart";
import { UserDetail } from "./UserDetail";
import { UserDetailDaily } from "./UserDetailDaily";
import { commonUtils } from "_helpers";
import { UserPopMap } from "./UserPopMap";
import Aux from "../hoc/_Aux";
import moment from "moment";
import sortBy from "lodash/sortBy";
import i18n from "../i18n";
class GroupDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            allusers: [],
            groups: [],
            selectedUser: "",
            selectedGroupnm: JSON.parse(localStorage.getItem("session")).defaultgrpnm,
            selectedGroupid: JSON.parse(localStorage.getItem("session")).defaultgrpid,
            temperatures: [],
            dateInterval: "daily",
            baseDay: moment(),
            startDay: moment().format("YYYY-MM-DD"),
            endDay: moment().format("YYYY-MM-DD"),
            groupStats: "",
            trangeYn: "N",
            tfrom: "-60",
            tto: "100",
            userDetailSelected: "",
            isUserDetail: false,
            isLocationMap: false,
            mapselectedUserNm: "",
            defaultLat: "",
            defaultLng: "",
            geolocations: [],
            display: "list",
            sortby: "asc",
        };
        this._handleChart = this._handleChart.bind(this);
        this._handleDate = this._handleDate.bind(this);
        this._handleDateInterval = this._handleDateInterval.bind(this);
        this._handleGroupSelect = this._handleGroupSelect.bind(this);
        this._handleFeverRange = this._handleFeverRange.bind(this);
        this._handleUserDetail = this._handleUserDetail.bind(this);
        this._handleUserClose = this._handleUserClose.bind(this);
        this._handleMap = this._handleMap.bind(this);
        this._handleUserMapClose = this._handleUserMapClose.bind(this);
        this._handleDisplay = this._handleDisplay.bind(this);

        this._handleTemperatureUsers = this._handleTemperatureUsers.bind(this);
        this._handleSort = this._handleSort.bind(this);
        this._handleParseIntSort = this._handleParseIntSort.bind(this);
    }

    _handleParseIntSort(sort) {
        this.setState((prevState) => ({
            users:
                prevState.sortby === "asc"
                    ? sortBy(this.state.users, (obj) => (sort === "tmax" ? parseFloat(obj[sort]) : parseInt(obj[sort], 10)))
                    : sortBy(this.state.users, (obj) => (sort === "tmax" ? parseFloat(obj[sort]) : parseInt(obj[sort], 10))).reverse(),
            sortby: prevState.sortby === "asc" ? "desc" : "asc",
        }));
    }

    _handleSort(sort) {
        this.setState((prevState) => ({
            users: prevState.sortby === "asc" ? sortBy(this.state.users, sort) : sortBy(this.state.users, sort).reverse(),
            sortby: prevState.sortby === "asc" ? "desc" : "asc",
        }));
    }

    _handleTemperatureUsers(flag) {
        const newUsers = this.state.allusers.filter((data) => data.tlabel === flag);
        this.setState({
            ...this.state,
            users: newUsers,
        });
    }

    _handleDisplay(display) {
        this.setState({
            ...this.state,
            display: display,
        });
    }

    _handleMap(userid, usernm) {
        this.props.getUserTemperatures(
            {
                from: this.state.startDay,
                to: this.state.endDay,
                userid: userid,
            },

            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        isLocationMap: !this.state.isLocationMap,
                        mapselectedUserNm: usernm,
                        defaultLat: result[0].lat,
                        defaultLng: result[0].lng,
                        geolocations: result,
                    });
                }
            }
        );
    }

    _handleUserMapClose() {
        this.setState({
            ...this.state,
            isLocationMap: !this.state.isLocationMap,
        });
    }

    _handleUserClose() {
        this.setState({
            ...this.state,
            isUserDetail: !this.state.isUserDetail,
        });
    }

    _handleUserDetail(e, userid) {
        e.preventDefault();
        this.setState({
            ...this.state,
            isUserDetail: !this.state.isUserDetail,
            userDetailSelected: userid,
        });
    }

    _handleGroupSelect(e, grpid) {
        e.preventDefault();
        this.setState(
            {
                ...this.state,
                selectedGroupnm: e.target.textContent,
                selectedGroupid: grpid,
                trangeYn: "N",
            },
            () => {
                this._getGroupStatsCount();
            }
        );
    }

    _handleDateInterval(flag) {
        this.setState(
            {
                ...this.state,
                baseDay: moment(),
                dateInterval: flag,
                startDay: commonUtils.statsDateRange(moment(), flag, "now", "start"),
                endDay: commonUtils.statsDateRange(moment(), flag, "now", "end"),
                trangeYn: "N",
            },
            () => {
                this._getGroupStatsCount();
            }
        );
    }

    _handleDate(flag) {
        const { dateInterval, baseDay } = this.state;
        this.setState(
            {
                ...this.state,
                baseDay: commonUtils.statsDateRange(baseDay, dateInterval, flag, "start"),
                startDay: commonUtils.statsDateRange(baseDay, dateInterval, flag, "start"),
                endDay: commonUtils.statsDateRange(baseDay, dateInterval, flag, "end"),
                trangeYn: "N",
            },
            () => {
                this._getGroupStatsCount();
            }
        );
    }

    _handleFeverRange(tfrom, tto) {
        this.setState(
            {
                ...this.state,
                tfrom: tfrom,
                tto: tto,
                trangeYn: "Y",
            },
            () => {
                this._getGroupUsers();
            }
        );
    }

    _handleChart() {
        this.props.getGroupUsersPeriodTemperatures(
            {
                grpid: this.state.selectedGroupid,
                interval: this.state.dateInterval,
                from: this.state.startDay,
                to: this.state.endDay,
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        temperatures: result,
                    });
                }
            }
        );
    }

    componentDidMount() {
        this._getGroups();
        this._getGroupStatsCount();
    }

    _getGroups() {
        this.props.getGroups({}, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    groups: result,
                });
            }
        });
    }

    _getGroupStatsCount() {
        this.props.getGroupStatsCount(
            {
                from: this.state.startDay,
                to: this.state.endDay,
                grpid: this.state.selectedGroupid,
                interval: this.state.dateInterval,
            },
            (error, result) => {
                if (result) {
                    this._getGroupUsers();
                    this.setState({
                        ...this.state,
                        groupStats: result,
                    });
                }
            }
        );
    }

    _getGroupUsers() {
        this.props.getGroupUsersStats(
            {
                grpid: this.state.selectedGroupid,
                interval: this.state.dateInterval,
                from: this.state.startDay,
                to: this.state.endDay,
                tfrom: this.state.tfrom,
                tto: this.state.tto,
                trangeYn: this.state.trangeYn,
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        users: result,
                        allusers: result,
                    });
                } else {
                    this.setState({
                        ...this.state,
                        users: [],
                    });
                }
            }
        );
    }

    render() {
        const { groupStats } = this.state;
        const session = JSON.parse(localStorage.getItem("session"));
        return (
            <Aux>
                <div className="main_content">
                    <div className="page_content">
                        <div className="container-fluid">
                            {/* <div className="row">
                                <div className="col-12">
                                    <div className="page_title_box">
                                        <h4>{i18n.t("menu_usergroup_monitor")}</h4>
                                    </div>
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-12">
                                    <div className="page_btn_box">
                                        <div className="btn-group group_select">
                                            <div className="btn-group-u">
                                                <a className="dropdown-toggle" data-toggle="dropdown" href="#">
                                                    {this.state.selectedGroupnm} <span className="caret"></span>
                                                </a>
                                                <ul className="dropdown-menu">
                                                    {this.state.groups &&
                                                        this.state.groups.map((data) => (
                                                            <li className={"dropdown-item depth" + data.lev} key={data.grpid}>
                                                                <a href="#" onClick={(e) => this._handleGroupSelect(e, data.grpid)}>
                                                                    {data.grpnm}
                                                                </a>
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                            <label className="btn btn-secondary active" onClick={() => this._handleDateInterval("daily")}>
                                                <input type="radio" name="options" id="option1" defaultChecked /> {i18n.t("label_btn_daily")}
                                            </label>
                                            <label className="btn btn-secondary" onClick={() => this._handleDateInterval("weekly")}>
                                                <input type="radio" name="options" id="option2" /> {i18n.t("label_btn_weekly")}
                                            </label>
                                            <label className="btn btn-secondary" onClick={() => this._handleDateInterval("monthly")}>
                                                <input type="radio" name="options" id="option3" /> {i18n.t("label_btn_monthly")}
                                            </label>
                                        </div>
                                        <div className="btn-group" role="group">
                                            <button type="button" className="btn" onClick={() => this._handleDate("prev")}>
                                                {i18n.t("label_btn_prev")}
                                            </button>
                                            <button type="button" className="btn" onClick={() => this._handleDate("next")}>
                                                {i18n.t("label_btn_next")}
                                            </button>
                                        </div>
                                        <div className="btn-group">
                                            <button type="button" className="btn" onClick={() => this._handleDateInterval("daily")}>
                                                {i18n.t("label_btn_today")}
                                            </button>
                                        </div>
                                        <div className="date_text" style={{ fontFamily: "verdana" }}>
                                            {this.state.dateInterval === "daily" ? this.state.startDay : this.state.startDay + "~" + this.state.endDay}
                                        </div>
                                        <div className="page_total_box">
                                            <span className="text">
                                                Total : <em className="count">{groupStats.allcnt}</em>
                                            </span>
                                            <span className="text">
                                                Measured : <em className="count">{groupStats.concnt}</em>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-xl-3">
                                    <div className="card user_temp normality">
                                        <div className="card-body" style={{ cursor: "pointer" }} onClick={() => this._handleTemperatureUsers("label_normal")}>
                                            <div className="media">
                                                <div className="media-body">
                                                    <p className="title">
                                                        {i18n.t("label_normal")}
                                                        <button type="button" className="btn_info"></button>
                                                        <span className="temp">
                                                            {groupStats.normalfrom} ~ {groupStats.normalto}
                                                        </span>
                                                    </p>
                                                    <h4 className="mb-0">
                                                        <span className="counter big">{groupStats.normalcnt}</span>
                                                        <span className="counter small">{groupStats.concnt}</span>
                                                    </h4>
                                                </div>
                                                <div className="avatar">
                                                    <span className="avatar_title">
                                                        <i className="ico_temp"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="progress_area">
                                                <p className="percent counter">
                                                    {groupStats.normalcnt > 0 ? ((groupStats.normalcnt / groupStats.concnt) * 100).toFixed(1) : 0}
                                                </p>
                                                <div className="progress">
                                                    <div
                                                        className="progress-bar active"
                                                        role="progressbar"
                                                        style={{ width: groupStats.concnt > 0 ? (groupStats.normalcnt / groupStats.concnt) * 100 : 0 + "%" }}
                                                        aria-valuenow={(groupStats.normalcnt / groupStats.concnt) * 100}
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3">
                                    <div className="card user_temp high">
                                        <div
                                            className="card-body"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => this._handleTemperatureUsers("label_high_fever")}
                                        >
                                            <div className="media">
                                                <div className="media-body">
                                                    <p className="title">
                                                        {i18n.t("label_high_fever")}
                                                        <button type="button" className="btn_info"></button>
                                                        <span className="temp">{groupStats.highfrom} ~ </span>
                                                    </p>
                                                    <h4 className="mb-0">
                                                        <span className="counter big">{groupStats.highcnt}</span>
                                                        <span className="counter small">{groupStats.concnt}</span>
                                                    </h4>
                                                </div>
                                                <div className="avatar">
                                                    <span className="avatar_title">
                                                        <i className="ico_temp"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="progress_area">
                                                <p className="percent counter">
                                                    {groupStats.highcnt > 0 ? ((groupStats.highcnt / groupStats.concnt) * 100).toFixed(1) : 0}
                                                </p>
                                                <div className="progress">
                                                    <div
                                                        className="progress-bar active"
                                                        role="progressbar"
                                                        style={{ width: groupStats.concnt > 0 ? (groupStats.highcnt / groupStats.concnt) * 100 : 0 + "%" }}
                                                        aria-valuenow={(groupStats.highcnt / groupStats.concnt) * 100}
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3">
                                    <div className="card user_temp middle_high">
                                        <div
                                            className="card-body"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => this._handleTemperatureUsers("label_midium_fever")}
                                        >
                                            <div className="media">
                                                <div className="media-body">
                                                    <p className="title">
                                                        {i18n.t("label_midium_fever")}
                                                        <button type="button" className="btn_info"></button>
                                                        <span className="temp">
                                                            {groupStats.moderatefrom} ~ {groupStats.moderateto}
                                                        </span>
                                                    </p>
                                                    <h4 className="mb-0">
                                                        <span className="counter big">{groupStats.middlecnt}</span>
                                                        <span className="counter small">{groupStats.concnt}</span>
                                                    </h4>
                                                </div>
                                                <div className="avatar">
                                                    <span className="avatar_title">
                                                        <i className="ico_temp"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="progress_area">
                                                <p className="percent counter">
                                                    {groupStats.middlecnt > 0 ? ((groupStats.middlecnt / groupStats.concnt) * 100).toFixed(1) : 0}
                                                </p>
                                                <div className="progress">
                                                    <div
                                                        className="progress-bar active"
                                                        role="progressbar"
                                                        style={{ width: groupStats.concnt > 0 ? (groupStats.middlecnt / groupStats.concnt) * 100 : 0 + "%" }}
                                                        aria-valuenow={(groupStats.middlecnt / groupStats.concnt) * 100}
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-3">
                                    <div className="card user_temp low_high">
                                        <div
                                            className="card-body"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => this._handleTemperatureUsers("label_mild_fever")}
                                        >
                                            <div className="media">
                                                <div className="media-body">
                                                    <p className="title">
                                                        {i18n.t("label_mild_fever")}
                                                        <button type="button" className="btn_info"></button>
                                                        <span className="temp">
                                                            {groupStats.lowfrom} ~ {groupStats.lowto}
                                                        </span>
                                                    </p>
                                                    <h4 className="mb-0">
                                                        <span className="counter big">{groupStats.lowcnt}</span>
                                                        <span className="counter small">{groupStats.concnt}</span>
                                                    </h4>
                                                </div>
                                                <div className="avatar">
                                                    <span className="avatar_title">
                                                        <i className="ico_temp"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="progress_area">
                                                <p className="percent counter">
                                                    {groupStats.lowcnt > 0 ? ((groupStats.lowcnt / groupStats.concnt) * 100).toFixed(1) : 0}
                                                </p>
                                                <div className="progress">
                                                    <div
                                                        className="progress-bar active"
                                                        role="progressbar"
                                                        style={{ width: groupStats.concnt > 0 ? (groupStats.lowcnt / groupStats.concnt) * 100 : 0 + "%" }}
                                                        aria-valuenow={groupStats.concnt > 0 && (groupStats.lowcnt / groupStats.concnt) * 100}
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 col-xl-12">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div style={{ float: "left", width: "100%" }}>
                                                        <h4 className="card-title" style={{ float: "left", width: "50%" }}>
                                                            {this.state.selectedGroupnm}
                                                        </h4>
                                                        <div style={{ float: "right", width: "50%", textAlign: "right" }}>
                                                            <i
                                                                className="fa fa-list fa-lg"
                                                                onClick={() => this._handleDisplay("list")}
                                                                style={{ cursor: "pointer" }}
                                                            ></i>
                                                            &nbsp;&nbsp;
                                                            <i
                                                                className="fa fa-th fa-lg"
                                                                onClick={() => this._handleDisplay("card")}
                                                                style={{ cursor: "pointer" }}
                                                            ></i>
                                                        </div>
                                                    </div>

                                                    {this.state.display === "list" && (
                                                        <div className="table-responsive">
                                                            <table className="table table-centered table-nowrap mb-0">
                                                                <thead className="thead-light">
                                                                    <tr>
                                                                        <th>{i18n.t("label_only-group")}</th>
                                                                        <th>
                                                                            {i18n.t("label_only-name")}
                                                                            <i
                                                                                className="fa fa-sort-amount-asc sort-icon"
                                                                                onClick={() => this._handleSort("usernm")}
                                                                            ></i>
                                                                        </th>
                                                                        <th>
                                                                            {i18n.t("label_only-id")}
                                                                            <i
                                                                                className="fa fa-sort-amount-asc sort-icon"
                                                                                onClick={() => this._handleSort("deviceid")}
                                                                            ></i>
                                                                        </th>
                                                                        <th>
                                                                            {i18n.t("label_only-temp")}
                                                                            <i
                                                                                className="fa fa-sort-amount-asc sort-icon"
                                                                                onClick={() => this._handleParseIntSort("tmax")}
                                                                            ></i>
                                                                        </th>
                                                                        <th>
                                                                            {i18n.t("label_only-spo2")}
                                                                            <i
                                                                                className="fa fa-sort-amount-asc sort-icon"
                                                                                onClick={() => this._handleParseIntSort("oxygenlast")}
                                                                            ></i>
                                                                        </th>
                                                                        <th>
                                                                            {i18n.t("label_only-hr")}
                                                                            <i
                                                                                className="fa fa-sort-amount-asc sort-icon"
                                                                                onClick={() => this._handleParseIntSort("heartlast")}
                                                                            ></i>
                                                                        </th>
                                                                        <th>
                                                                            {i18n.t("label_only-bp")}
                                                                            <i
                                                                                className="fa fa-sort-amount-asc sort-icon"
                                                                                onClick={() => this._handleParseIntSort("sbloodlast")}
                                                                            ></i>
                                                                        </th>
                                                                        <th>
                                                                            {i18n.t("label_only-rr")}
                                                                            <i
                                                                                className="fa fa-sort-amount-asc sort-icon"
                                                                                onClick={() => this._handleParseIntSort("breathlast")}
                                                                            ></i>
                                                                        </th>
                                                                        <th>
                                                                            {i18n.t("label_only-step")}
                                                                            <i
                                                                                className="fa fa-sort-amount-asc sort-icon"
                                                                                onClick={() => this._handleParseIntSort("steplast")}
                                                                            ></i>
                                                                        </th>
                                                                        <th>
                                                                            {i18n.t("label_only-battery")}
                                                                            <i
                                                                                className="fa fa-sort-amount-asc sort-icon"
                                                                                onClick={() => this._handleParseIntSort("battery")}
                                                                            ></i>
                                                                        </th>
                                                                        <th>
                                                                            {i18n.t("label_lastupdate")}
                                                                            <i
                                                                                className="fa fa-sort-amount-asc sort-icon"
                                                                                onClick={() => this._handleSort("lastupdated")}
                                                                            ></i>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.users &&
                                                                        this.state.users.map((data, index) => (
                                                                            <tr
                                                                                className="col-12"
                                                                                key={data.userid}
                                                                                onClick={(e) => this._handleUserDetail(e, data.userid)}
                                                                                style={{ cursor: "pointer" }}
                                                                            >
                                                                                <td>{data.grpnm}</td>
                                                                                <td>{data.usernm}</td>
                                                                                <td>{data.deviceid}</td>
                                                                                <td>{session.tdisptype === "C" ? data.tmax : data.tfmax}</td>
                                                                                <td>{data.oxygenlast}</td>
                                                                                <td>{data.heartlast}</td>
                                                                                <td>
                                                                                    {data.sbloodlast}/{data.dbloodlast}
                                                                                </td>
                                                                                <td>{data.breathlast}</td>
                                                                                <td>{data.steplast}</td>
                                                                                <td>{data.battery}</td>
                                                                                <td>{commonUtils.localTimezoneDate2(data.lastupdated)}</td>
                                                                            </tr>
                                                                        ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )}
                                                    {this.state.display === "card" && (
                                                        <div className="row">
                                                            {this.state.users &&
                                                                this.state.users.map((data, index) => (
                                                                    <div className="col-md-6 col-xl-3 card_wrap" key={index}>
                                                                        <a
                                                                            href="#"
                                                                            className="item-link"
                                                                            onClick={(e) => this._handleUserDetail(e, data.userid)}
                                                                        >
                                                                            <div className={"card user_temp " + data.tlabel}>
                                                                                <div className="card-body">
                                                                                    <div className={"personal_info " + data.tlabel}>
                                                                                        <div className="text_area">
                                                                                            <p className="name">{data.usernm}</p>
                                                                                            <p className="dept">{data.grpnm}</p>
                                                                                            <div className="device">
                                                                                                <p className="update">
                                                                                                    <span>
                                                                                                        {commonUtils.localTimezoneDate2(data.lastupdated)}
                                                                                                    </span>{" "}
                                                                                                    updated
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="ico_area">
                                                                                            <div className="avatar">
                                                                                                <span className="avatar_title">
                                                                                                    <i className="ico_temp"></i>
                                                                                                </span>
                                                                                            </div>
                                                                                            <p className="status">{i18n.t(data.tlabel)}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="care_info">
                                                                                        <div className="temp" style={{ width: "80%" }}>
                                                                                            <div className="item">
                                                                                                <p className="tit">
                                                                                                    {/* Temperature {" (°" + session.tdisptype + ")"} */}
                                                                                                    <b>
                                                                                                        Max Temp (
                                                                                                        {this.state.dateInterval === "daily"
                                                                                                            ? commonUtils.localTimezoneTime(data.maxsensingtime)
                                                                                                            : commonUtils.localTimezoneDay(data.maxsensingtime)}
                                                                                                        )
                                                                                                    </b>
                                                                                                </p>
                                                                                                <p className="data">
                                                                                                    <span className="num">
                                                                                                        {session.tdisptype === "C" ? data.tmax : data.tfmax}
                                                                                                    </span>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="etc">
                                                                                            <div className="etc_top">
                                                                                                <div className="item">
                                                                                                    <p className="tit">
                                                                                                        Temp {" (°" + session.tdisptype + ")"}
                                                                                                    </p>
                                                                                                    <p className="data">
                                                                                                        <span className="num">
                                                                                                            {session.tdisptype === "C"
                                                                                                                ? data.tlast
                                                                                                                : data.tflast}{" "}
                                                                                                            (
                                                                                                            {session.tdisptype === "C"
                                                                                                                ? (data.tmax - data.tlast).toFixed(1)
                                                                                                                : (data.tfmax - data.tflast).toFixed(1)}
                                                                                                            )
                                                                                                        </span>
                                                                                                    </p>
                                                                                                </div>

                                                                                                <div className="item">
                                                                                                    <p className="tit">SPO2</p>
                                                                                                    <p className="data">
                                                                                                        <span className="num">{data.oxygenlast}</span> %
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className="item">
                                                                                                    <p className="tit">Heart rate</p>
                                                                                                    <p className="data">
                                                                                                        <span className="num">{data.heartlast}</span> bpm
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="etc_bottom">
                                                                                                <div className="item">
                                                                                                    <p className="tit">
                                                                                                        Blood <span className="block">Pressure</span>
                                                                                                    </p>
                                                                                                    <p className="data">
                                                                                                        <span className="num">
                                                                                                            {data.sbloodlast}/{data.dbloodlast}
                                                                                                        </span>
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className="item">
                                                                                                    <p className="tit">
                                                                                                        Respir<span className="abbr">atory</span>
                                                                                                    </p>
                                                                                                    <p className="data">
                                                                                                        <span className="num">{data.breathlast}</span> bpm
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className="item">
                                                                                                    <p className="tit">Step</p>
                                                                                                    <p className="data">
                                                                                                        <span className="num">{data.steplast}</span>
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <div className="care_info">
                                                                                        <div className="row">
                                                                                            <div className="col-4 care_item">
                                                                                                <p className="tit">Temperature</p>
                                                                                                <p className="data">
                                                                                                    <span className="num">
                                                                                                        {session.tdisptype === "C" ? data.tmax : data.tfmax}
                                                                                                    </span>{" "}
                                                                                                    {" °" + session.tdisptype}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className="col-4 care_item">
                                                                                                <p className="tit">SPO2</p>
                                                                                                <p className="data">
                                                                                                    <span className="num">{data.oxygenlast}</span> %
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className="col-4 care_item">
                                                                                                <p className="tit">Heart rate</p>
                                                                                                <p className="data">
                                                                                                    <span className="num">{data.heartlast}</span> bpm
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-4 care_item">
                                                                                                <p className="tit">Blood Pressure</p>
                                                                                                <p className="data">
                                                                                                    <span className="num">
                                                                                                        {data.sbloodlast}/{data.dbloodlast}
                                                                                                    </span>
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className="col-4 care_item">
                                                                                                <p className="tit">Respiratory</p>
                                                                                                <p className="data">
                                                                                                    <span className="num">{data.breathlast}</span> bpm
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className="col-4 care_item">
                                                                                                <p className="tit">Step</p>
                                                                                                <p className="data">
                                                                                                    <span className="num">{data.steplast}</span>
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div> */}
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isUserDetail && this.state.dateInterval !== "daily" && (
                    <UserDetail
                        userid={this.state.userDetailSelected}
                        startDay={this.state.startDay}
                        endDay={this.state.endDay}
                        interval={this.state.dateInterval}
                        open={this.state.isUserDetail}
                        onClose={this._handleUserClose}
                    />
                )}
                {this.state.isUserDetail && this.state.dateInterval === "daily" && (
                    <UserDetailDaily
                        userid={this.state.userDetailSelected}
                        startDay={this.state.startDay}
                        endDay={this.state.endDay}
                        interval={this.state.dateInterval}
                        open={this.state.isUserDetail}
                        onClose={this._handleUserClose}
                    />
                )}

                {this.state.isLocationMap && (
                    <UserPopMap
                        open={this.state.isLocationMap}
                        onClose={this._handleUserMapClose}
                        selectedUser={this.state.mapselectedUserNm}
                        defaultLat={this.state.defaultLat}
                        defaultLng={this.state.defaultLng}
                        geolocations={this.state.geolocations}
                    />
                )}
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getGroupUsersPeriodTemperatures,
            getGroupStatsCount,
            getGroupUsersStats,
            getGroups,
            getUserTemperatures,
            dispatch,
        },
        dispatch
    );

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(GroupDashboard);
export { connectedPage as GroupDashboard };
