import _ from "lodash";
import moment from "moment";
export const commonUtils = {
    isValidNumber,
    isValidEmail,
    hasData,
    dataPaging,
    statsDateRange,
    utcDate,
    utcPeriodicDate,
    localTimezoneDate,
    localTimezoneDate2,
    localTimezoneTime,
    localTimezoneTime2,
    localTimezoneTimes,
    localTimezoneDay,
    addDays,
    dayOfWeek,
};

function isValidNumber(value) {
    return window.$.isNumeric(value);
}

function isValidEmail(value) {
    var reg = new RegExp("^[\\w\\-]+(\\.[\\w\\-_]+)*@[\\w\\-]+(\\.[\\w\\-]+)*(\\.[a-zA-Z]{2,3})$", "gi");
    if (reg.test(value)) {
        return true;
    }
    return false;
}

function hasData(obj) {
    return obj && obj[0] ? true : false;
}

function dataPaging(items, pageNumber, pageSize) {
    const startIndex = (pageNumber - 1) * pageSize; // 자를 배열의 시작점

    return _(items)
        .slice(startIndex) // 시작점부터 배열을 자르되
        .take(pageSize) // pageSize만큼의 배열을 취함
        .value(); // lodash wrapper 객체를 regular 배열로 변환
}

function utcDate(data, flag) {
    return flag === "start"
        ? moment(data).startOf("day").utc(true).format("YYYY-MM-DD HH:mm:ss")
        : moment(data).endOf("day").utc(true).format("YYYY-MM-DD HH:mm:ss");
}

function utcPeriodicDate(data, flag) {
    return flag === "start" ? moment(data).startOf("day").utc(true).format("YYYY-MM-DD") : moment(data).endOf("day").utc(true).format("YYYY-MM-DD");
}

function addDays(data, add) {
    return moment(data, "YYYY-MM-DD").add(add, "days").format("YYYY-MM-DD");
}

function localTimezoneDate(data) {
    // var tzName = moment.tz.guess();
    // return moment.utc(data).tz(tzName).format("YYYY-MM-DD HH:mm:ss");

    return moment(data).format("YYYY-MM-DD HH:mm:ss");
}

function localTimezoneDate2(data) {
    var tzName = moment.tz.guess();
    return moment.utc(data).tz(tzName).format("YYYY-MM-DD HH:mm:ss");
}

function localTimezoneDay(data) {
    //var tzName = moment.tz.guess();
    //return moment.utc(data).tz(tzName).format("MM/DD");

    return moment(data).format("MM/DD");
}

function localTimezoneTime(data) {
    //var tzName = moment.tz.guess();
    //return moment.utc(data).tz(tzName).format("HH:mm");

    return moment(data).format("HH:mm");
}

function localTimezoneTime2(data) {
    var tzName = moment.tz.guess();
    return moment.utc(data).tz(tzName).format("HH:mm");
}

function localTimezoneTimes(data) {
    var tzName = moment.tz.guess();
    return moment.utc(data).tz(tzName).format("HH");
    //return moment.tz(data, tzName).format("HH:mm");
}

function statsDateRange(baseDay, interval, flag, value) {
    const letdate = moment(baseDay, "YYYY-MM-DD");
    if (interval === "daily") {
        if (flag === "prev") {
            return letdate.subtract(1, "days").format("YYYY-MM-DD");
        } else if (flag === "next") {
            return letdate.add(1, "days").format("YYYY-MM-DD");
        } else {
            return letdate.format("YYYY-MM-DD");
        }
    } else if (interval === "weekly") {
        if (flag === "prev") {
            return value === "start"
                ? letdate.subtract(1, "weeks").startOf("isoWeek").format("YYYY-MM-DD")
                : letdate.subtract(1, "weeks").endOf("isoWeek").format("YYYY-MM-DD");
        } else if (flag === "next") {
            return value === "start"
                ? letdate.add(1, "weeks").startOf("isoWeek").format("YYYY-MM-DD")
                : letdate.add(1, "weeks").endOf("isoWeek").format("YYYY-MM-DD");
        } else {
            return value === "start" ? moment().startOf("isoWeek").format("YYYY-MM-DD") : moment().endOf("isoWeek").format("YYYY-MM-DD");
        }
    } else if (interval === "monthly") {
        if (flag === "prev") {
            return value === "start"
                ? letdate.subtract(1, "months").startOf("month").format("YYYY-MM-DD")
                : letdate.subtract(1, "months").endOf("month").format("YYYY-MM-DD");
        } else if (flag === "next") {
            return value === "start"
                ? letdate.add(1, "months").startOf("month").format("YYYY-MM-DD")
                : letdate.add(1, "months").endOf("month").format("YYYY-MM-DD");
        } else {
            return value === "start" ? moment().startOf("month").format("YYYY-MM-DD") : moment().endOf("month").format("YYYY-MM-DD");
        }
    }
}

function dayOfWeek(startDay, sub) {
    let day = moment(startDay).subtract(sub, "d").format("MM-DD");
    let week = moment(moment(startDay).subtract(sub, "d").format("YYYY-MM-DD")).format("dddd");

    let dayofweek = "";
    if (week === "Monday") dayofweek = "(월)";
    if (week === "Tuesday") dayofweek = "(화)";
    if (week === "Wednesday") dayofweek = "(수)";
    if (week === "Thursday") dayofweek = "(목)";
    if (week === "Friday") dayofweek = "(금)";
    if (week === "Saturday") dayofweek = "(토)";
    if (week === "Sunday") dayofweek = "(일)";

    return day + dayofweek;
}
