/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import windowSize from "react-window-size";
import Pagination from "react-js-pagination";
import { commonUtils } from "_helpers";
import { getTenants, getTenantAdmins, deleteTenantUser } from "_services";
import { Tenant } from "./Tenant";
import { TenantAdmin } from "./TenantAdmin";
import Aux from "../hoc/_Aux";
import i18n from "../i18n";
class Administrator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isTenant: false,
            isTenantUser: false,
            tenantid: "",
            activePage: 1,
            pageSize: 14,
            currentPage: 1,
            tenants: [],
            tenantusers: [],
            userInfo: "",
        };
        this._handleTenant = this._handleTenant.bind(this);
        this._handleTenantUser = this._handleTenantUser.bind(this);
        this._handleSaveClose = this._handleSaveClose.bind(this);
        this._handleTenantUSerSaveClose = this._handleTenantUSerSaveClose.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._getTenants = this._getTenants.bind(this);
        this._deleteTenantUser = this._deleteTenantUser.bind(this);
        this._haneldUser = this._haneldUser.bind(this);
    }
    _haneldUser(data) {
        this.setState({
            ...this.state,
            isTenantUser: !this.state.isTenantUser,
            userInfo: data,
        });
    }

    _deleteTenantUser(tenantid, userid) {
        this.props.deleteTenantUser({ tenantid: tenantid, userid: userid }, (error, result) => {
            if (result) {
                this._getTenantAdmins();
            }
        });
    }

    _handleTenantUser() {
        this.setState({
            ...this.state,
            isTenantUser: !this.state.isTenantUser,
            userInfo: "",
        });
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState(
            {
                [name]: value,
            },
            () => {
                this._getTenantAdmins();
            }
        );
    }

    _handleSaveClose() {
        this._getTenants();
        this._handleTenant();
    }

    _handleTenantUSerSaveClose() {
        this._getTenantAdmins();
        this._handleTenantUser();
    }

    _handleTenant() {
        this.setState({
            ...this.state,
            isTenant: !this.state.isTenant,
        });
    }
    _handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber,
            currentPage: pageNumber,
        });
    }
    componentDidMount() {
        this._getTenants();
    }

    _getTenants() {
        this.props.getTenants({}, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    tenants: result,
                });
            }
        });
    }
    _getTenantAdmins() {
        this.props.getTenantAdmins({ tenantid: this.state.tenantid }, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    tenantusers: result,
                });
            }
        });
    }
    render() {
        const apipaginate = commonUtils.dataPaging(this.state.tenantusers, this.state.currentPage, this.state.pageSize);
        return (
            <Aux>
                <div className="main_content">
                    <div className="page_content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page_title_box">
                                        <h4>{i18n.t("menu_administrator")}</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form_box">
                                                        <div className="form-group">
                                                            <select
                                                                className="form-control "
                                                                name="tenantid"
                                                                value={this.state.tenantid}
                                                                onChange={this._handleChange}
                                                                style={{ width: "200px" }}
                                                            >
                                                                <option value="A">{i18n.t("label_tenant")}</option>
                                                                {this.state.tenants &&
                                                                    this.state.tenants.map((data) => (
                                                                        <option key={data.tenantid} value={data.tenantid}>
                                                                            {data.tenantid}
                                                                        </option>
                                                                    ))}
                                                            </select>{" "}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form_box">
                                                        <div className="text-sm-right">
                                                            <button type="button" className="btn btn-success" onClick={this._handleTenant}>
                                                                <i className="bx bx-plus"></i> {i18n.t("label_btn_addtenant")}
                                                            </button>
                                                            &nbsp;
                                                            {this.state.tenantid && (
                                                                <button type="button" className="btn btn-primary" onClick={this._handleTenantUser}>
                                                                    <i className="bx bx-plus"></i> {i18n.t("label_btn_addadministrator")}
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table table-centered table-nowrap">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col">{i18n.t("label_userid")}</th>
                                                            <th scope="col">{i18n.t("label_usernm")}</th>
                                                            <th scope="col">{i18n.t("label_password")}</th>
                                                            <th scope="col">{i18n.t("label_created")}</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.tenantusers &&
                                                            apipaginate.map((data) => (
                                                                <tr className="col-12" key={data.userid}>
                                                                    <td style={{ cursor: "pointer" }}>
                                                                        <span onClick={() => this._haneldUser(data)}>{data.userid}</span>
                                                                    </td>
                                                                    <td>{data.usernm}</td>
                                                                    <td>{data.password}</td>
                                                                    <td>{commonUtils.localTimezoneDate2(data.createdtime)}</td>
                                                                    <td className="btn_td">
                                                                        <button
                                                                            type="button"
                                                                            title="Delete"
                                                                            className="ico_btn delete"
                                                                            onClick={() => this._deleteTenantUser(data.tenantid, data.userid)}
                                                                        >
                                                                            <i className="bx bxs-trash"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                                {this.state.tenantusers.length > 0 && (
                                                    <div className="data_page">
                                                        <Pagination
                                                            activePage={this.state.activePage}
                                                            itemsCountPerPage={14}
                                                            totalItemsCount={this.state.tenantusers.length}
                                                            pageRangeDisplayed={10}
                                                            onChange={this._handlePageChange.bind(this)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.isTenant && <Tenant open={this.state.isTenant} onClose={this._handleTenant} onSave={this._handleSaveClose} />}
                {this.state.isTenantUser && (
                    <TenantAdmin
                        open={this.state.isTenantUser}
                        onClose={this._handleTenantUser}
                        onSave={this._handleTenantUSerSaveClose}
                        tenantid={this.state.tenantid}
                        userInfo={this.state.userInfo}
                    />
                )}
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            deleteTenantUser,
            getTenantAdmins,
            getTenants,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(Administrator);
export { connectedPage as Administrator };
// export default connect(mapStateToProps, mapDispatchToProps)(windowSize(Administrator));
