/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getGateways, deleteGateway } from "../_services";
import { FeverRange } from "./FeverRange";
import { FeverRangeInterest } from "./FeverRangeInterest";
import { TemperatureDisplay } from "./TemperatureDisplay";
import Aux from "../hoc/_Aux";
import i18n from "../i18n";
class Settings extends Component {
    constructor(props) {
        super(props);

        this.state = {};
        this._handleChange = this._handleChange.bind(this);
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
    }

    componentDidMount() {}

    render() {
        return (
            <Aux>
                <div className="main_content">
                    <div className="page_content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page_title_box">
                                        <h4>{i18n.t("menu_setting")}</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="card cont_h100">
                                        <div className="card-body">
                                            <div className="nav flex-column nav-pills" role="tablist" aria-orientation="vertical">
                                                <a
                                                    className="nav-link mb-2 active"
                                                    id="v-pills-fever-tab"
                                                    data-toggle="pill"
                                                    href="#feverRange"
                                                    role="tab"
                                                    aria-controls="v-pills"
                                                    aria-selected="true"
                                                >
                                                    {i18n.t("label_feverclass")}
                                                </a>
                                                <a
                                                    className="nav-link mb-2"
                                                    id="v-pills-interest-tab"
                                                    data-toggle="pill"
                                                    href="#interest"
                                                    role="tab"
                                                    aria-controls="v-pills"
                                                    aria-selected="false"
                                                >
                                                    {i18n.t("label_alertlevel")}
                                                </a>
                                                <a
                                                    className="nav-link mb-2"
                                                    id="v-pills-tdisplay-tab"
                                                    data-toggle="pill"
                                                    href="#tdisplay"
                                                    role="tab"
                                                    aria-controls="v-pills"
                                                    aria-selected="false"
                                                >
                                                    {i18n.t("label-temperature-display")}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="tab-content" id="v-pills-tabContent">
                                        <div className="tab-pane fade active show" id="feverRange" role="tabpanel" aria-labelledby="v-pills-fever-tab">
                                            <FeverRange />
                                        </div>
                                        <div className="tab-pane fade" id="interest" role="tabpanel" aria-labelledby="v-pills-interest-tab">
                                            <FeverRangeInterest />
                                        </div>
                                        <div className="tab-pane fade" id="tdisplay" role="tabpanel" aria-labelledby="v-pills-tdisplay-tab">
                                            <TemperatureDisplay />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            deleteGateway,
            getGateways,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(Settings);
export { connectedPage as Settings };
// export default connect(mapStateToProps, mapDispatchToProps)(windowSize(Settings));
