import React from "react";
import ReactEcharts from "echarts-for-react";
// import echarts from "echarts";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { commonUtils } from "_helpers";
// import { Modal } from "react-bootstrap";
import moment from "moment-timezone";
import Aux from "../hoc/_Aux";

class TermperaturePatternWeekly extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUser: this.props.selectedUser,
            interval: this.props.interval,
            option: this.getOption(this.props.healthpatterns, this.props.interval, this.props.tdisptype, this.props.startDay, this.props.tabtype),
        };
        this._handleOnClose = this._handleOnClose.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.healthpatterns !== this.props.healthpatterns) {
            this.setState({
                ...this.state,
                option: this.getOption(this.props.healthpatterns, this.props.interval, this.props.tdisptype, this.props.startDay, this.props.tabtype),
            });
        }

        if (prevProps.tabtype !== this.props.tabtype) {
            this.setState({
                ...this.state,
                option: this.getOption(this.props.healthpatterns, this.props.interval, this.props.tdisptype, this.props.startDay, this.props.tabtype),
            });
        }
    }

    _handleOnClose() {
        this.props.onClose();
    }

    getOption = (healthpatterns, interval, tdisptype, startDay, type) => {
        let timeSeries = [];
        let Atemp = [];
        let Btemp = [];
        let Ctemp = [];
        let Dtemp = [];

        let formatter = "";
        let texttitle = "";

        if (type === "temperature") {
            formatter = "°" + tdisptype;
        } else if (type === "spo2") {
            formatter = "%";
        } else if (type === "heart") {
            formatter = "bpm";
        } else if (type === "blood") {
            formatter = "";
            texttitle = "※ 수축기 혈압";
        } else if (type === "respiratory") {
            formatter = "bpm";
        } else if (type == "step") {
            formatter = "";
        }

        for (let temp of healthpatterns) {
            if (interval === "daily") {
                let healths = "";
                if (type === "temperature") {
                    healths = temp.temperature;
                } else if (type === "spo2") {
                    healths = temp.oxygen;
                } else if (type === "heart") {
                    healths = temp.heart;
                } else if (type === "blood") {
                    healths = temp.sblood;
                } else if (type === "respiratory") {
                    healths = temp.breath;
                } else if (type === "step") {
                    healths = temp.step;
                }

                if (temp.gbn === "A") {
                    timeSeries.push(temp.times);
                    Atemp.push(healths);
                } else if (temp.gbn === "B") {
                    Btemp.push(healths);
                } else if (temp.gbn === "C") {
                    Ctemp.push(healths);
                } else if (temp.gbn === "D") {
                    Dtemp.push(healths);
                }
            }
        }

        const option = {
            title: {
                left: "left",
                text: type === "blood" ? texttitle : "※ 과거 같은 요일 4주 패턴",
                textStyle: {
                    fontSize: 16,
                },
            },
            legend: {
                data: [
                    commonUtils.dayOfWeek(startDay, 0),
                    commonUtils.dayOfWeek(startDay, 7),
                    commonUtils.dayOfWeek(startDay, 14),
                    commonUtils.dayOfWeek(startDay, 21),
                ],
            },
            tooltip: {
                trigger: "axis",
            },
            xAxis: {
                data: timeSeries,
                boundaryGap: false,
            },
            yAxis: {
                splitLine: { show: true },
                axisLabel: {
                    formatter: "{value} " + formatter,
                },

                min: function (item) {
                    var diff = item.max - item.min;
                    if (diff === 0) {
                        diff = 1;
                    }
                    return (item.min - diff * 0.2).toFixed(1);
                },
            },
            grid: [
                {
                    top: 50,
                    left: 50,
                    right: 42,
                },
            ],
            series: [
                {
                    type: "line",
                    name: commonUtils.dayOfWeek(startDay, 0),
                    showSymbol: false,
                    connectNulls: false,
                    lineStyle: {
                        normal: {
                            width: 3,
                        },
                    },
                    data: Atemp,
                },
                {
                    type: "line",
                    name: commonUtils.dayOfWeek(startDay, 7),
                    showSymbol: false,
                    connectNulls: false,
                    lineStyle: {
                        normal: {
                            width: 3,
                        },
                    },
                    data: Btemp,
                },
                {
                    type: "line",
                    name: commonUtils.dayOfWeek(startDay, 14),
                    showSymbol: false,
                    connectNulls: false,
                    lineStyle: {
                        normal: {
                            width: 3,
                        },
                    },
                    data: Ctemp,
                },
                {
                    type: "line",
                    name: commonUtils.dayOfWeek(startDay, 21),
                    showSymbol: false,
                    connectNulls: false,
                    lineStyle: {
                        normal: {
                            width: 3,
                        },
                    },
                    data: Dtemp,
                },
            ],
        };

        return option;
    };

    render() {
        let sheight = this.props.tabtype === "blood" ? 200 : 250;
        return (
            <Aux>
                <div className="graph_area">
                    <ReactEcharts notMerge ref="echarts_react" option={this.state.option} style={{ height: sheight }} />
                </div>
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(TermperaturePatternWeekly);
export { connectedPage as TermperaturePatternWeekly };
