/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getUserRecentDevice } from "_services";
import { Modal } from "react-bootstrap";
import { commonUtils } from "_helpers";

import i18n from "../i18n";
class UserDevice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userDevices: "",
            userid: "",
        };

        this._handleOnClose = this._handleOnClose.bind(this);
    }

    componentDidMount() {
        this._getUserdevices();
    }

    _getUserdevices() {
        this.props.getUserRecentDevice(
            {
                userid: this.props.userid,
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        userDevices: result,
                    });
                }
            }
        );
    }

    _handleOnClose() {
        this.props.onClose();
    }

    render() {
        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={() => {
                        this._handleOnClose();
                    }}
                    dialogClassName="modal-dialog modal-dialog-centered modal-xlg"
                    backdrop="static"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{i18n.t("label_user_smartband")}</h5>

                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    this._handleOnClose();
                                }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="table-responsive maxheight270">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">{i18n.t("label_devicecnt")}</th>
                                            <th scope="col">{i18n.t("label_created")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.userDevices &&
                                            this.state.userDevices.map((data) => (
                                                <tr className="col-12" key={data.deviceid}>
                                                    <td className="">{data.deviceid}</td>

                                                    <td style={{ fontFamily: "verdana" }}>{commonUtils.localTimezoneDate2(data.createdtime)}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getUserRecentDevice,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(UserDevice);
export { connectedPage as UserDevice };
