import React from "react";
import ReactEcharts from "echarts-for-react";
import echarts from "echarts";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { commonUtils } from "_helpers";
import sortBy from "lodash/sortBy";
import Aux from "../hoc/_Aux";

class DashboardChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            option: this.getOption(this.props.temperatures),
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.temperatures !== this.props.temperatures) {
            this.setState({
                ...this.state,
                option: this.getOption(this.props.temperatures),
            });
        }
    }

    getOption = (temp) => {
        const temperatures = sortBy(temp, "lastsensingtime");
        const option = {
            animation: true,
            grid: {
                top: 50,
                left: 50,
                right: 50,
                bottom: 20,
            },
            xAxis: {
                data: (function () {
                    let res = [];
                    for (let temp of temperatures) {
                        res.push(commonUtils.localTimezoneTime(temp.lastsensingtime));
                    }
                    return res;
                })(),
            },

            yAxis: {
                type: "value",
                axisLabel: {
                    inside: false,
                    formatter: "{value}",
                },
                splitLine: {
                    lineStyle: {
                        type: "dashed",
                    },
                },
                scale: true,
                min: 34.0,
                max: 41.0,
                splitNumber: 5,
            },

            series: {
                type: "scatter",
                symbolSize: 20,
                emphasis: {
                    label: {
                        show: true,
                        formatter: function (param) {
                            return param.data;
                        },
                        position: "top",
                    },
                },
                itemStyle: {
                    shadowBlur: 10,
                    shadowColor: "rgba(120, 36, 50, 0.5)",
                    shadowOffsetY: 5,
                    color: new echarts.graphic.RadialGradient(0.4, 0.3, 1, [
                        {
                            offset: 0,
                            color: "rgb(251, 118, 123)",
                        },
                        {
                            offset: 1,
                            color: "rgb(204, 46, 72)",
                        },
                    ]),
                },

                data: (function () {
                    let res = [];
                    for (let temp of temperatures) {
                        res.push(temp.tlast);
                    }
                    return res;
                })(),
            },
        };

        return option;
    };

    render() {
        return (
            <Aux>
                <ReactEcharts notMerge ref="echarts_react" option={this.state.option} style={{ height: 440 }} />
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(DashboardChart);
export { connectedPage as DashboardChart };
