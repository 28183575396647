/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setDevice, getModels } from "../_services";
import { openAlertDialog } from "../_actions";
import { Modal } from "react-bootstrap";
import i18n from "../i18n";
class Device extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: "",
            devicetype: "",
            deviceid: "",
            devicenm: "",
            description: "",
            models: [],
            devicetypes: [],
        };

        this._handleOnClose = this._handleOnClose.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._handleRequest = this._handleRequest.bind(this);
    }

    _handleRequest(e) {
        e.preventDefault();
        const { model, devicetype, deviceid } = this.state;
        if (!model) {
            this.props.dispatch(openAlertDialog(i18n.t("msg_alert_devicemodel"), false, "check", false, null, null, null, null));
            return;
        }
        if (!devicetype) {
            this.props.dispatch(openAlertDialog(i18n.t("msg_alert_devicetype"), false, "check", false, null, null, null, null));
            return;
        }
        if (!deviceid) {
            this.props.dispatch(openAlertDialog(i18n.t("msg_alert_deviceid"), false, "check", false, null, null, null, null));
            return;
        }
        const session = JSON.parse(localStorage.getItem("session"));
        const device = {
            tenantid: session.tenantid,
            model: this.state.model,
            devicetype: this.state.devicetype,
            deviceid: this.state.deviceid,
            devicenm: this.state.devicenm,
            description: this.state.description,
        };

        this.props.setDevice(device, (error, result) => {
            if (result) {
                //this.props.dispatch(openAlertDialog("OK", false, "information", false, null, null, null, null));
                this._handleSaveClose();
            }
        });
    }

    _handleSaveClose() {
        this.props.onSave();
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
    }

    componentDidMount() {
        this.props.getModels({}, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    models: result.filter((e) => e.type === "M"),
                    devicetypes: result.filter((e) => e.type === "T"),
                });
            }
        });
    }

    _handleOnClose() {
        this.props.onClose();
    }

    render() {
        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={() => {
                        this._handleOnClose();
                    }}
                    dialogClassName="modal-dialog modal-dialog-centered sz_xlg"
                    backdrop="static"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{i18n.t("label_device")}</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    this._handleOnClose();
                                }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <form className="form-horizontal">
                                <div className="form-group">
                                    <label className="required">{i18n.t("label_devicemodel")}</label>
                                    <select className="form-control" name="model" value={this.state.model} onChange={this._handleChange}>
                                        <option value="">{i18n.t("label_devicemodel")}</option>
                                        {this.state.models &&
                                            this.state.models.map((data) => (
                                                <option key={data.mdid} value={data.modelnm}>
                                                    {data.modelnm}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label className="required">{i18n.t("label_devicetype")}</label>
                                    <select
                                        className="form-control"
                                        name="devicetype"
                                        value={this.state.devicetype}
                                        onChange={this._handleChange}
                                        style={{ width: "260px", height: "40px" }}
                                    >
                                        <option value="">{i18n.t("label_devicetype")}</option>
                                        {this.state.devicetypes &&
                                            this.state.devicetypes.map((data) => (
                                                <option key={data.mdid} value={data.modelnm}>
                                                    {data.modelnm}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label className="required">{i18n.t("label_deviceid")}</label>
                                    <input className="form-control " type="text" value={this.state.deviceid} onChange={this._handleChange} name="deviceid" />
                                </div>
                                <div className="form-group">
                                    <label className="required">{i18n.t("label_devicenm")}</label>
                                    <input className="form-control " type="text" value={this.state.devicenm} onChange={this._handleChange} name="devicenm" />
                                </div>
                                <div className="form-group">
                                    <label>{i18n.t("label_description")}</label>
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        value={this.state.description}
                                        onChange={this._handleChange}
                                        name="description"
                                    ></textarea>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={(e) => {
                                    this._handleRequest(e);
                                }}
                            >
                                {i18n.t("label_btn_save")}
                            </button>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => {
                                    this._handleOnClose();
                                }}
                            >
                                {i18n.t("label_btn_cancel")}
                            </button>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getModels,
            setDevice,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(Device);
export { connectedPage as Device };
