import { requestHeaders, handleResponse, commonUtils } from "_helpers";
import { getGroupStatsReq } from "_actions";
// import moment from "moment";

//Group DashBoard LineChart
export function getGroupUsersPeriodTemperatures(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const timezone = localStorage.getItem("time-zone");
        const from = commonUtils.utcDate(params.from, "start");
        const to = commonUtils.utcDate(params.to, "end");
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
                "X-CLIENT-TIMEZONE": timezone,
            }),
        };

        return fetch(
            `/api/stats/groups/${params.grpid}/temperatures?interval=${params.interval}&model=${session.model}&from=${from}&to=${to}&tfrom=${params.tfrom}&tto=${params.tto}`,
            requestOptions
        )
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                //dispatch(getUsersMonitorReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getGroupUsersList(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const timezone = localStorage.getItem("time-zone");
        const from = commonUtils.utcDate(params.from, "start");
        const to = commonUtils.utcDate(params.to, "end");
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
                "X-CLIENT-TIMEZONE": timezone,
            }),
        };
        return fetch(
            `/api/stats/groups/${params.grpid}/users?interval=${params.interval}&model=${session.model}&from=${from}&to=${to}&tfrom=${params.tfrom}&tto=${params.tto}`,
            requestOptions
        )
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                //dispatch(getUsersMonitorReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

// Scheduled Statistic ===================================================================================================================================================

export function getGroupStatsCount(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const timezone = localStorage.getItem("time-zone");
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
                "X-CLIENT-TIMEZONE": timezone,
            }),
        };
        // const from = commonUtils.utcPeriodicDate(params.from, "start");
        // const to = commonUtils.utcPeriodicDate(params.to, "end");

        const to = commonUtils.addDays(params.to, 1);

        return fetch(`/api/stats/groups/${params.grpid}?interval=${params.interval}&from=${params.from}&to=${to}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getGroupStatsReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getGroupUsersStats(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const timezone = localStorage.getItem("time-zone");
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
                "X-CLIENT-TIMEZONE": timezone,
            }),
        };

        const to = commonUtils.addDays(params.to, 1);

        return fetch(
            `/api/stats/groups/${params.grpid}/users?interval=${params.interval}&from=${params.from}&to=${to}&tfrom=${params.tfrom}&tto=${params.tto}&trangeYn=${params.trangeYn}`,
            requestOptions
        )
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getGroupStatsReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getAllGroupUsersStats(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const timezone = localStorage.getItem("time-zone");
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
                "X-CLIENT-TIMEZONE": timezone,
            }),
        };
        const to = commonUtils.addDays(params.from, 1);

        return fetch(
            `/api/stats/groups/${params.grpid}/users?interval=${params.interval}&from=${params.from}&to=${to}&tfrom=${params.tfrom}&tto=${params.tto}&trangeYn=${params.trangeYn}`,
            requestOptions
        )
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getGroupStatsReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getUserDeltaStats(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const timezone = localStorage.getItem("time-zone");
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
                "X-CLIENT-TIMEZONE": timezone,
            }),
        };

        // const from = commonUtils.utcPeriodicDate(params.from, "start");
        // const to = commonUtils.utcPeriodicDate(params.to, "end");

        const to = commonUtils.addDays(params.to, 1);

        return fetch(`/api/stats/users/${params.userid}/delta?interval=${params.interval}&from=${params.from}&to=${to}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getGroupStatsReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getUserTemperatures(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const timezone = localStorage.getItem("time-zone");
        const from = commonUtils.utcDate(params.from, "start");
        const to = commonUtils.utcDate(params.to, "end");
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
                "X-CLIENT-TIMEZONE": timezone,
            }),
        };
        return fetch(`/api/stats/users/${params.userid}?from=${from}&to=${to}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                //dispatch(getUsersMonitorReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getUserTemperaturesPattern(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const timezone = localStorage.getItem("time-zone");
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
                "X-CLIENT-TIMEZONE": timezone,
            }),
        };

        const to = commonUtils.addDays(params.to, 1);

        return fetch(`/api/stats/users/${params.userid}/health?interval=${params.interval}&from=${params.from}&to=${to}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getGroupStatsReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getUserTemperaturesWeeklyPattern(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const timezone = localStorage.getItem("time-zone");
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
                "X-CLIENT-TIMEZONE": timezone,
            }),
        };

        const to = commonUtils.addDays(params.to, 1);

        return fetch(`/api/stats/users/${params.userid}/health/week?interval=${params.interval}&from=${params.from}&to=${to}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getGroupStatsReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}
