import { requestHeaders, requestBody, handleResponse } from "_helpers";
import {
    getDevicesReq,
    setDevicesReq,
    getModelsReq,
    setModelsReq,
    getConnectDevicesReq,
    getGatewaysReq,
    setGatewaysReq,
    deleteGatewaysReq,
    deleteDevicesReq,
} from "../_actions";

export function deleteDevice(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const requestOptions = {
            method: "DELETE",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
            }),
        };
        return fetch(`/api/devices?deviceid=${params.deviceid}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(deleteGatewaysReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function deleteGateway(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const requestOptions = {
            method: "DELETE",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
            }),
        };
        return fetch(`/api/gateways?gid=${params.gid}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(deleteDevicesReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function setGateway(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const body = params;
        const requestOptions = {
            method: "POST",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
            }),
            body: requestBody(body),
        };
        return fetch(`/api/gateways`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setGatewaysReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getGateways(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
            }),
        };
        return fetch(`/api/gateways?searchStr=${params.searchStr}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getGatewaysReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function setModel(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const body = params;
        const requestOptions = {
            method: "POST",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
            body: requestBody(body),
        };
        return fetch(`/api/models`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setModelsReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getModels(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
            }),
        };
        return fetch(`/api/models`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getModelsReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function setDevice(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const body = params;
        const requestOptions = {
            method: "POST",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
            body: requestBody(body),
        };
        return fetch(`/api/devices`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setDevicesReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getDevices(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
            }),
        };
        return fetch(`/api/devices?model=${session.model}&devicetype=${params.devicetype}&searchStr=${params.searchStr}&sort=${params.sort}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getDevicesReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getConnectDevices(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const session = JSON.parse(localStorage.getItem("session"));
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
                "X-CLIENT-TENANT": session.tenantid,
            }),
        };
        return fetch(
            `/api/devices/connect?model=${session.model}&devicetype=${params.devicetype}&searchStr=${params.searchStr}&sort=${params.sort}`,
            requestOptions
        )
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getConnectDevicesReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}
