import { GroupDashboard, DeviceManagement, GatewayManagement, UserManagement, UserGroupManagement, Dashboard, Settings, Administrator } from "_pages";

export const routes = [
    {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
        exact: true,
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        exact: true,
    },
    {
        path: "/groupdashboard",
        name: "Group Dashboard",
        component: GroupDashboard,
        exact: true,
    },
    {
        path: "/user",
        name: "User Management",
        component: UserManagement,
        exact: true,
    },
    {
        path: "/device",
        name: "Device Management",
        component: DeviceManagement,
        exact: true,
    },
    {
        path: "/gateway",
        name: "AtoZ Management",
        component: GatewayManagement,
        exact: true,
    },
    {
        path: "/group",
        name: "User Group Management",
        component: UserGroupManagement,
        exact: true,
    },
    {
        path: "/settings",
        name: "Settings",
        component: Settings,
        exact: true,
    },
    {
        path: "/administrator",
        name: "administrator",
        component: Administrator,
        exact: true,
    },
];
