/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCode, setCommonCode } from "../_services";
import { openAlertDialog } from "../_actions";
import i18n from "../i18n";
class TemperatureDisplay extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tdisplay: "",
        };

        this._handleChange = this._handleChange.bind(this);
        this._setTemperatureDisplay = this._setTemperatureDisplay.bind(this);
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            ...this.state,
            [name]: value,
        });
    }

    componentDidMount() {
        this._getTemperatureDisplay();
    }

    _setTemperatureDisplay() {
        const session = JSON.parse(localStorage.getItem("session"));
        this.props.setCommonCode({ tenantid: session.tenantid, codeid: "C0014", customid1: this.state.tdisplay }, (error, result) => {
            if (result) {
                this.props.dispatch(openAlertDialog(i18n.t("msg_alert_saved"), false, "check", false, null, null, null, null));
            }
        });
    }

    _getTemperatureDisplay() {
        this.props.getCode({ codeid: "C0014" }, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    tdisplay: result.customid1,
                });
            }
        });
    }

    render() {
        return (
            <>
                <div className="card cont_h100">
                    <div className="card-body">
                        <h4 className="card-title">{i18n.t("label-temperature-display")}</h4>
                        <div className="title_btn">
                            <button type="button" className="btn btn-success" onClick={this._setTemperatureDisplay}>
                                <i className="bx bx-check-double"></i> {i18n.t("label_btn_save")}
                            </button>
                        </div>

                        <div className="form-group row" id="codesRow">
                            {/* <label htmlFor="example-text-input" className="col-sm-2 col-form-label text-right">
                                {i18n.t("label-temperature-display")}
                            </label> */}
                            <div className="col-sm-5">
                                <select name="tdisplay" onChange={this._handleChange} value={this.state.tdisplay} className="form-control with_text">
                                    <option value="C">{i18n.t("label_celsius")}(°C)</option>
                                    <option value="F">{i18n.t("label_fahrenheit")}(°F)</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setCommonCode,
            getCode,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(TemperatureDisplay);
export { connectedPage as TemperatureDisplay };
