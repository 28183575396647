/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import i18n from "../../i18n";
export class AppMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedMenu: this.props.menus[0] ? this.props.menus[0] : [],
        };
        this._handleClickMenu = this._handleClickMenu.bind(this);
    }
    _handleClickMenu(e, menu) {
        e.preventDefault();
        this.setState({
            ...this.state,
            selectedMenu: menu,
        });

        this.props.history.push({
            pathname: menu.path,
            state: { menuId: menu.id, menu: menu },
        });
    }

    render() {
        const { menus } = this.props;
        const session = JSON.parse(localStorage.getItem("session"));
        return (
            <div className="vertical_menu">
                <div className="wrapper">
                    <div className="mask">
                        <div className="offset">
                            <div className="sidebar_content_wrapper">
                                <div className="sidebar_content">
                                    <div className="sidebar_menu">
                                        <ul className="metismenu">
                                            {menus.map((menu) => {
                                                return menu.show ? (
                                                    <li key={menu.id} className={menu.class + (this.state.selectedMenu.id === menu.id ? " selected" : "")}>
                                                        <a href="#" title={menu.label} onClick={(e) => this._handleClickMenu(e, menu)}>
                                                            <i className={menu.icon}></i>
                                                            <span>{menu.title}</span>
                                                        </a>
                                                    </li>
                                                ) : null;
                                            })}
                                            {session.adminyn === "X" && (
                                                <li className={this.state.selectedMenu.id === "administrator" ? " selected" : ""}>
                                                    <a
                                                        href="#"
                                                        title={"Administrator"}
                                                        onClick={(e) =>
                                                            this._handleClickMenu(e, {
                                                                id: "administrator",
                                                                path: "/administrator",
                                                            })
                                                        }
                                                    >
                                                        <i className={"bx bx-cog"}></i>
                                                        <span>{i18n.t("menu_administrator")}</span>
                                                    </a>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
