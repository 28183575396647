/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getConnectDevices, getModels, setUserDeviceConnect } from "_services";
import Pagination from "react-js-pagination";
import { commonUtils } from "_helpers";
import { Modal } from "react-bootstrap";
import { openAlertDialog } from "_actions";
import i18n from "../i18n";
class DeviceConnect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: "A",
            devicetype: "A",
            searchStr: "",
            sort: "createdtime",
            devices: [],
            models: [],
            devicetypes: [],
            activePage: 1,
            pageSize: 10,
            currentPage: 1,
        };

        this._handleOnClose = this._handleOnClose.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._getDevices = this._getDevices.bind(this);
        this._handleRequest = this._handleRequest.bind(this);
    }

    _handleSaveClose() {
        this.props.onSave();
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
    }

    componentDidMount() {
        this._getModels();
        this._getDevices();
    }

    _getModels() {
        this.props.getModels({}, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    models: result.filter((e) => e.type === "M"),
                    devicetypes: result.filter((e) => e.type === "T"),
                });
            }
        });
    }
    _handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber,
            currentPage: pageNumber,
        });
    }

    _getDevices() {
        const { model, devicetype, searchStr, sort } = this.state;
        this.props.getConnectDevices(
            {
                model: model,
                devicetype: devicetype,
                searchStr: searchStr,
                sort: sort,
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        devices: result,
                    });
                }
            }
        );
    }

    _handleRequest(e, deviceid) {
        this.props.setUserDeviceConnect({ connect: "Y", userid: this.props.selecedtUserid, deviceid: deviceid }, (error, result) => {
            if (result) {
                this.props.dispatch(openAlertDialog("OK", false, "information", false, null, null, null, null));
                this._handleSaveClose();
            }
        });
    }

    _handleOnClose() {
        this.props.onClose();
    }

    render() {
        const apipaginate = commonUtils.dataPaging(this.state.devices, this.state.currentPage, this.state.pageSize);
        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={() => {
                        this._handleOnClose();
                    }}
                    dialogClassName="modal-dialog modal-dialog-centered  modal-lg"
                    backdrop="static"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{i18n.t("label_device")}</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    this._handleOnClose();
                                }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form_box">
                                        <div className="form-group">
                                            <select className="form-control" name="model" value={this.state.model} onChange={this._handleChange}>
                                                <option value="A">{i18n.t("label_devicemodel")}</option>
                                                {this.state.models &&
                                                    this.state.models.map((data) => (
                                                        <option key={data.mdid} value={data.modelnm}>
                                                            {data.modelnm}
                                                        </option>
                                                    ))}
                                            </select>
                                            &nbsp;
                                            <select className="form-control " name="devicetype" value={this.state.devicetype} onChange={this._handleChange}>
                                                <option value="A">{i18n.t("label_devicetype")}</option>
                                                {this.state.devicetypes &&
                                                    this.state.devicetypes.map((data) => (
                                                        <option key={data.mdid} value={data.modelnm}>
                                                            {data.modelnm}
                                                        </option>
                                                    ))}
                                            </select>
                                            &nbsp;
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={i18n.t("label_devicenm")}
                                                name="searchStr"
                                                value={this.state.searchStr}
                                                onChange={this._handleChange}
                                            />
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" type="button" onClick={this._getDevices}>
                                                    <i className="bx bx-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>{i18n.t("label_devicemodel")}</th>
                                            <th>{i18n.t("label_devicetype")}</th>
                                            <th>{i18n.t("label_deviceid")}</th>
                                            <th>{i18n.t("label_devicenm")}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.devices &&
                                            apipaginate.map((data) => (
                                                <tr className="col-12" key={data.deviceid}>
                                                    <td>{data.model}</td>
                                                    <td>{data.devicetype}</td>
                                                    <td>{data.deviceid}</td>
                                                    <td>{data.devicenm}</td>
                                                    <td className="btn_td" style={{ textAlign: "right" }}>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn-sm"
                                                            onClick={(e) => this._handleRequest(e, data.deviceid)}
                                                        >
                                                            Connect
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}

                                        {this.state.devices.length === 0 && (
                                            <tr className="col-12">
                                                <td colSpan="4">{i18n.t("msg_searchresult")}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                                {this.state.devices.length > 0 && (
                                    <div className="data_page">
                                        <Pagination
                                            activePage={this.state.activePage}
                                            itemsCountPerPage={10}
                                            totalItemsCount={this.state.devices.length}
                                            pageRangeDisplayed={10}
                                            onChange={this._handlePageChange.bind(this)}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setUserDeviceConnect,
            getModels,
            getConnectDevices,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(DeviceConnect);
export { connectedPage as DeviceConnect };
