/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getCommonCode, setFeverRange } from "../_services";
import { openAlertDialog } from "../_actions";
// import { Constants } from "../_constants/constants";
import i18n from "../i18n";
class FeverRange extends Component {
    constructor(props) {
        super(props);

        this.state = {
            feverRange: {
                // normalto: "",
                // normalfrom: "",
                // lowto: "",
                // lowfrom: "",
                // highto: "",
                // highfrom: "",
                // moderateto: "",
                // moderatefrom: "",
                // etcto: "",
                // etcfrom: "",
            },
            codes: [],
        };

        this._handleChange = this._handleChange.bind(this);
        this._setFeverRange = this._setFeverRange.bind(this);
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            feverRange: {
                ...this.state.feverRange,
                [name]: value,
            },
        });
    }

    componentDidMount() {
        this._getFeverRange();
    }

    _setFeverRange() {
        const { feverRange } = this.state;
        const codes = [
            { codeid: "C0002", customid1: feverRange.normalfrom, customid2: feverRange.normalto },
            { codeid: "C0003", customid1: feverRange.lowfrom, customid2: feverRange.lowto },
            { codeid: "C0004", customid1: feverRange.moderatefrom, customid2: feverRange.moderateto },
            { codeid: "C0005", customid1: feverRange.highfrom, customid2: feverRange.highto },
            { codeid: "C0006", customid1: feverRange.etcfrom, customid2: feverRange.etcto },
        ];
        this.props.setFeverRange({ codes: codes }, (error, result) => {
            if (result) {
                this.props.dispatch(openAlertDialog(i18n.t("msg_alert_saved"), false, "check", false, null, null, null, null));
            }
        });
    }

    _getFeverRange() {
        this.props.getCommonCode({ codeid: "C0001" }, (error, result) => {
            if (result) {
                const feverRange = {};
                for (let i = 0; i < result.length; i++) {
                    const from = result[i].codenm + "from";
                    const to = result[i].codenm + "to";
                    feverRange[from] = result[i].customid1;
                    feverRange[to] = result[i].customid2;
                }
                this.setState({
                    ...this.state,
                    codes: result,
                    feverRange: feverRange,
                });
            }
        });
    }

    render() {
        return (
            <>
                <div className="card cont_h100">
                    <div className="card-body">
                        <h4 className="card-title">{i18n.t("label_feverclass")}</h4>
                        <div className="title_btn">
                            <button type="button" className="btn btn-success" onClick={this._setFeverRange}>
                                <i className="bx bx-check-double"></i> {i18n.t("label_btn_save")}
                            </button>
                        </div>

                        {this.state.codes &&
                            this.state.codes.map((data, index) => {
                                let codenm = "";
                                if (data.codenm === "normal") {
                                    codenm = i18n.t("label_normal");
                                } else if (data.codenm === "low") {
                                    codenm = i18n.t("label_mild_fever");
                                } else if (data.codenm === "moderate") {
                                    codenm = i18n.t("label_midium_fever");
                                } else if (data.codenm === "high") {
                                    codenm = i18n.t("label_high_fever");
                                } else if (data.codenm === "etc") {
                                    codenm = i18n.t("label_etc");
                                }

                                return (
                                    <div className="form-group row" key={data.codeid} id="codesRow">
                                        <label htmlFor="example-text-input" className="col-sm-2 col-form-label text-right">
                                            {codenm}
                                        </label>

                                        <div className="col-sm-5">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="From"
                                                name={data.codenm + "from"}
                                                value={this.state.feverRange[data.codenm + "from"]}
                                                onChange={this._handleChange}
                                            />
                                        </div>

                                        <div className="col-sm-5">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="To"
                                                name={data.codenm + "to"}
                                                value={this.state.feverRange[data.codenm + "to"]}
                                                onChange={this._handleChange}
                                            />
                                        </div>
                                    </div>
                                );
                            })}

                        {/* {this.state.codes &&
                            this.state.codes.map((data, index) => (
                                <div className="form-group row" key={data.codeid} id="codesRow">
                                    <label htmlFor="example-text-input" className="col-sm-2 col-form-label text-right">
                                        {data.customid3}
                                    </label>

                                    <div className="col-sm-5">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="From"
                                            name={data.codenm + "from"}
                                            value={this.state.feverRange[data.codenm + "from"]}
                                            onChange={this._handleChange}
                                        />
                                    </div>

                                    <div className="col-sm-5">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="To"
                                            name={data.codenm + "to"}
                                            value={this.state.feverRange[data.codenm + "to"]}
                                            onChange={this._handleChange}
                                        />
                                    </div>
                                </div>
                            ))} */}
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            setFeverRange,
            getCommonCode,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(FeverRange);
export { connectedPage as FeverRange };
