import React from "react";
import ReactEcharts from "echarts-for-react";
import echarts from "echarts";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { commonUtils } from "_helpers";
import Aux from "../hoc/_Aux";

class UserBreathChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            option: this.getOption(this.props.temperatures, this.props.interval),
            interval: this.props.interval,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.temperatures !== this.props.temperatures) {
            this.setState({
                ...this.state,
                option: this.getOption(this.props.temperatures, this.props.interval),
            });
        }
    }

    getOption = (temperatures, interval) => {
        const option = {
            title: {},
            animation: true,
            tooltip: {
                trigger: "axis",
            },
            grid: {
                top: 40,
                left: 55,
                right: 50,
                //bottom: 20,
            },
            xAxis: [
                {
                    data: (function () {
                        let res = [];
                        for (let temp of temperatures) {
                            if (interval === "daily") {
                                res.push(commonUtils.localTimezoneTime(temp.times));
                            } else {
                                res.push(commonUtils.localTimezoneDay(temp.times));
                            }
                            //res.push(commonUtils.localTimezoneTimes(temp.times));
                        }
                        return res;
                    })(),
                    boundaryGap: false,
                },
            ],
            yAxis: {
                type: "value",
                axisLabel: {
                    inside: false,
                    formatter: "{value} bpm",
                },
                min: function (item) {
                    var diff = item.max - item.min;
                    if (diff === 0) {
                        diff = 1;
                    }
                    return (item.min - diff * 0.2).toFixed(2);
                },
                splitArea: {
                    show: true,
                    areaStyle: {
                        opacity: 0.1,
                    },
                },
            },

            series: {
                type: "line",
                connectNulls: false,
                symbolSize: 10,
                lineStyle: {
                    normal: {
                        color: "#ea6f21",
                        width: 4,
                    },
                },
                tooltip: {
                    show: true,
                },
                areaStyle: {
                    normal: {
                        color: new echarts.graphic.LinearGradient(
                            0,
                            0,
                            0,
                            1,
                            [
                                {
                                    offset: 0,
                                    color: "rgba(236, 169, 44, 1)",
                                },
                                {
                                    offset: 1,
                                    color: "rgba(236, 169, 44,0)",
                                },
                            ],
                            false
                        ),
                    },
                },
                markPoint: {
                    data: [
                        { type: "max", name: "max" },
                        { type: "min", name: "min" },
                    ],
                },
                markLine: {
                    data: [{ type: "average", name: "avg" }],
                },
                data: (function () {
                    let res = [];

                    for (let temp of temperatures) {
                        res.push(temp.breath);
                    }
                    return res;
                })(),
            },
        };

        return option;
    };

    render() {
        return (
            <Aux>
                <ReactEcharts notMerge ref="echarts_react" option={this.state.option} style={{ height: 250 }} />
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(UserBreathChart);
export { connectedPage as UserBreathChart };
