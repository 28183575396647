import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactExport from "react-data-export";
import { getUserTemperatures } from "_services";
// import Aux from "../hoc/_Aux";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExcelDownload extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <div>
                <ExcelFile hideElement="true" filename={this.props.usernm + "_" + this.props.startDay}>
                    <ExcelSheet data={this.props.temperatures} name={this.props.usernm}>
                        <ExcelColumn label="Time" value="times" />
                        <ExcelColumn label="Temperature" value="tmax" />
                        <ExcelColumn label="SPO2" value="oxygenlast" />
                        <ExcelColumn label="Heart Rate" value="heartlast" />
                        <ExcelColumn label="Blood Pressure" value="sbloodlast" />
                        <ExcelColumn label="Blood Pressure" value="dbloodlast" />
                        <ExcelColumn label="Respiratory" value="breathlast" />
                        <ExcelColumn label="Step" value="steplast" />
                    </ExcelSheet>
                </ExcelFile>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getUserTemperatures,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(ExcelDownload);
export { connectedPage as ExcelDownload };
